import { Box, Button, Card, Typography } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import error from "../images/error.jpg";
import VerifiedIcon from '@mui/icons-material/Verified';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import SendIcon from '@mui/icons-material/Send';


const GatewayError = () => {

    const navigate = useNavigate();

    const data = window.location.pathname;
    const id = data.replace("/eezib/gatewayError/","");
    console.log("data : ",data , "  id : ",id);

    React.useEffect(() => {
        if(id){
            //navigate("/", {state : {error: id }})
            //window.alert("error occurred..")
        }
    },[id])


  return (
    <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', height:'100vh' }} >
        <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between' }} >
            <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', width:'50%' }} >
            <Box component='img' src={error} sx={{ width:"90%"  }} />
            </Box>
            <Box sx={{ width:"50%", textAlign:'center', display:'flex', flexDirection:'column', alignItems:'center' }} >
                <Typography className="errorText" sx={{ fontFamily:"montserrat", fontWeight:700, fontSize:"2.8rem" }} >
                    An Error Has Been Occured
                </Typography>
                <Typography sx={{ fontFamily:"montserrat", fontWeight:600, fontSize:'1.5rem', textTransform:"capitalize", color:'#618ffa' }} >while placing your order</Typography>

                <Card elevation={12} sx={{ display:'flex', alignItems:'center', justifyContent:'center', p:2, flexDirection:'column', mt:5, borderRadius:2 }} >
                    < Typography sx={{ fontFamily:'montserrat', fontWeight:500, textTransform:'capitalize' }} >Please share mentioned ID with eezib admin </Typography>
                    <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', gap:1 }} >
                        <VerifiedIcon sx={{ color:'#57ad76' }} />
                        <Typography className="idText" sx={{ fontFamily:'montserrat', fontWeight:800, fontSize:'2rem' }} >{id}</Typography>
                    </Box>

                    <Box sx={{ }} >
                        {/* <AlternateEmailIcon/> */}
                        <Typography sx={{ fontFamily:"montserrat", fontWeight:500, color:'#a1a1a1' }}  >support@eezib.com</Typography>
                    </Box>


                </Card>

                    <Button endIcon={ <SendIcon/> } onClick={() => navigate("/" , {replace:true})} sx={{ mt:8, fontFamily:'montserrat', fontWeight:500 }} variant='contained' > back to eezib vouchers </Button>

            </Box>
        </Box>
    </Box>
  )
}

export default GatewayError