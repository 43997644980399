import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import env from "react-dotenv";
import Cookies from "js-cookie";
import { ApiFile } from "../components/ApiHandler";

export const orderSlice = createAsyncThunk("orderSlice" , async (data) => {
    const auth = Cookies.getItem("voucherDemoAuth");
    console.log( 'order data : ', data );

    console.log("order token = ",data.token)
    const userData = data.userDetails

    const url = env.REACT_APP_UAPI_URL;

    try{

        // const api = await fetch(`${url}/eezibapi/order`, {
        //     method:"POST",
        //     headers:{
        //         "Content-Type":'application/json',
        //         'Authorization':`Bearer ${auth}`
        //     },
        //     body:JSON.stringify(userData)
        // })

        const api = env.REACT_APP_UAPI_URL;
        const apiUrl = `${api}/eezibapi/order`;
        const method = "POST";
        
        const json = await ApiFile({"apiUrl":apiUrl , "method":method, "bodyData":userData, "authAccess":auth });


        console.log("order response : ",json);

        if(api.status === 200 ){
            return api.json();
        }
        if(api.status === 401 ){
            window.alert("Session Expire. Please login to continue");
            // setTimeout(() => {
            //     window.location.replace(process.env.REACT_APP_UAPI_URL)
            // },1000)
        }
    }catch(err){
        console.log("err : ",err)
    }

})

const order = createSlice({
    name:"order",
    initialState:{
        item:null,
        isLoading:false,
        isError:false
    },
    extraReducers:(builder) => {
        builder.addCase(orderSlice.pending , (state , action) => {
            state.isLoading=true;
            state.item=null;
        });

        builder.addCase(orderSlice.fulfilled , (state , action) => {
            state.item = action.payload;
            state.isLoading = false;
        });
        
        builder.addCase(orderSlice.rejected , (state ,action) => {
            state.isError = action.payload;
            state.isLoading = false;
        })
    }
})

export default order.reducer