import React from 'react'
import { Alert, AppBar, Box, Breadcrumbs, Chip, CircularProgress, Divider, FormControl, InputAdornment, InputLabel, Link, MenuItem, Paper, Select, Snackbar, Toolbar, Typography, emphasize } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import LandingMode from './LandingMode';
import BestOffers from './BestOffers';
import CashBackOffer from './FoodCashBackOffer';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import image from '../images/eezib2.png';
import image2 from "../images/eezib.png"
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css';
import env from 'react-dotenv';
import eezib from "../images/eezib.png"
import {TextField} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import PasswordIcon from '@mui/icons-material/Password';
import LogoutIcon from '@mui/icons-material/Logout';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../redux/userAuth';
import Cookies from 'js-cookie';
import { ApiFile } from './ApiHandler';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { Cookie, Living } from '@mui/icons-material';

import accessories from "../categoryImg/accessories.jpg";
import automobile from "../categoryImg/automobiles.jpg";
import billpay from "../categoryImg/billpay.jpeg";
import electronics from "../categoryImg/electronic.jpg";
import entertainment from "../categoryImg/entertainment.png";
import groccery from "../categoryImg/groccery.jpg";
import healthImg from "../categoryImg/health.webp";
import kids from "../categoryImg/kids.jpg";
import lifestyle from "../categoryImg/lifestyle.jpeg";
import restaurant from "../categoryImg/restaurant.jpg";
import shopping from "../categoryImg/shopping.jpg";
import sports from "../categoryImg/sports.jpg";
import subscription from "../categoryImg/subscription.jpeg";
import tourism from "../categoryImg/tourism.jpg";
import offer from "../images/amazingDiscount.png";
import AmazingOffer from './AmazingOffer';


function Landing() {

  const [dialog, setDialog] = React.useState(false);
  const [tkn, settkn] = React.useState('');
  const [id, setId] = React.useState('');
  const [u_name, setUName] = React.useState('');
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const [appBarStyle, setAppBarStyle] = React.useState({
    background: 'linear-gradient(20deg, rgb(0, 149, 255), rgb(30, 255, 240))',
    height: "4.9rem",
    transition: "all 0.3rem"
  })
  const [imageSource, setImageSource] = React.useState(image);
  const [scrolling, setScrolling] = React.useState(false);
  const [userName , setUserName] = React.useState('');
  const [password , setPassword] = React.useState('');
  const [name , setName] = React.useState('');
  const [user, setUser] = React.useState([]);
  const [loginDialog , setLoginDialog] = React.useState(false);
  const [user_id, setUserId] = React.useState("");
  const [loading , setLoading] = React.useState(false);
  const [loginUser , setLoginUser] = React.useState(false)

  const  [ handleSuccess , setHandleSuccess ] = React.useState(false);
  const [ jsonSuccess, setJsonSuccess ] = React.useState("");

  const [ handleError, setHandleError ] = React.useState(false);
  const [ jsonError , setJsonError ] = React.useState("");

  const [ searchedText , setSearchedText ] = React.useState('');
  const [ filteredData , setFilteredData ] = React.useState();

  const [  mouseOver , setMouseOver ] = React.useState(false);

  const [ qrCode , setQrcode ] = React.useState("");

  const location = useLocation();
  const dispatch = useDispatch();

  const handleMouseEnter = () => {
    setMouseOver(true);
  }

  const handleMouseLeave = () => {
    setMouseOver(false);
  }

  const successClose = () => {
    setHandleSuccess(false);
  }

  const errorClose = () => {
    setJsonError(false);
  }

  console.log("location state : ",location.state);


  React.useEffect(() => {
    // try{   
      
      if(location.state){

        console.log("location state data : ",location.state);

        if(location.state.data){
          console.log("landing location : ",location.state.data[0])
          const respo = location.state.data[0];
          console.log("respo : ",respo)
          const tkn = respo.jwt;
          const qr = respo?.qr
          setQrcode(qr)
          const userId = respo.userDetails;
          const uName = respo.name;
          setUName(uName)
          setId(userId)
          settkn(tkn);
          if(uName && tkn && userId ){
            setLoginUser(true);
          }
          const id =  Cookies.get("voucherDemoName");
          const token = Cookies.get("voucherDemoAuth");
          const name = Cookies.get("voucherDemoName");
            setUName(name);
            settkn(token);
            setId(id);
        }

        if(location.state?.error){
          // window.alert( `entered here with id - ${window.location.error}` );
          // console.log("entered error section");

          console.log("location state data : ",location.state.error);

        }


      
    }else{
            const name = Cookies.get("voucherDemoName");
        setUName(name);
      // window.alert("Hold on! Direct access to this page isn't supported.");
      // window.location.reload();
      // navigate(-1)

    }

    // }catch(err){
    //   console.log(err)
    // }
  }, []);

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
    const threshold = 550;
    if (window.scrollY > threshold) {
      setAppBarStyle({
        background: "white",
        height: "4rem",
        transition: "all 0.3s"
      });
      setScrolling(true)
      setImageSource(image2)
    } else {
      setAppBarStyle({
        background: 'linear-gradient(20deg, rgb(0, 149, 255), rgb(30, 255, 240))',
        height: "4.9rem",
        transition: "all 0.3s"
      });
      setImageSource(image);
      setScrolling(false)
    }
  }

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }

    
  }, [])

  function handleClose() {
    setDialog(false)
  }

  const data = [
    {
      index: 1,
      name: "jewelry and lifestyle",
      value: "lifestyle",
      image : lifestyle
    },
    {
      index: 2,
      name: "Books_Magazines & Subscriptions",
      value: "subscriptions",
      image : subscription
    },
    {
      index: 3,
      name: "beauty_health",
      value: "health",
      image: healthImg
    },
    
    {
      index: 5,
      name: "sports",
      value: "sports",
      image : sports
    },
    {
      index: 6,
      name: "Travel",
      value: "tourism",
      image : tourism,
    },
    {
      index: 9,
      name: "Grocery and Retail",
      value: "grocery",
      image: groccery
    },
    {
      index: 11,
      name: "Deals & Subscriptions",
      value: "deals",
      image : subscription 
    },
    {
      index: 12,
      name: "Mobile Recharge",
      value: "recharges",
      image: billpay
    },
    {
      index: 13,
      name: "Restaurants Foods and Drinks",
      value: "restaurants",
      image : restaurant
    },
    {
      index: 14,
      name: "Music Movies and Entertainment",
      value: "entertainment",
      image: entertainment
    },
    {
      index: 15,
      name: "Electronics",
      value: "electronics",
      image: electronics
    },
    {
      index: 18,
      name: "Baby & Kids",
      value: "kids",
      image : kids
    },
    {
      index: 19,
      name: "Apparel_Fashion & Accessories",
      value: "accessories",
      image: accessories
    },
    {
      index: 21,
      name: "Automobile",
      value: "automobile",
      image : automobile,
    },
  ]

  const navigate = useNavigate();

  const items = [
    <Box component='img' onClick={() => navigate(`/categories/lifestyle`, { state: { data: [{ "value": 'lifestyle', "token": tkn, "id": id }] } })} sx={{borderRadius:"15px", width:{xs:"18rem" , md:"22rem", lg:'22rem'}}} src="./eezib/alice/ADDIDAS.jpg" />,
    <Box component='img' onClick={() => navigate(`/categories/restaurants`, { state: { data: [{ "value": 'restaurants', "token": tkn, "id": id }] } })} sx={{borderRadius:"15px", width:{xs:"18rem" , md:"22rem", lg:'22rem'}}} src="./eezib/alice/blinkit.jpg" />,
    <Box component='img' onClick={() => navigate(`/categories/lifestyle`, { state: { data: [{ "value": 'lifestyle', "token": tkn, "id": id }] } })} sx={{borderRadius:"15px", width:{xs:"18rem" , md:"22rem", lg:'22rem'}}} src="./eezib/alice/amazon2.jpg" />,
      <Box component='img' onClick={() => navigate(`/categories/restaurants`, { state: { data: [{ "value": 'restaurants', "token": tkn, "id": id }] } })} sx={{borderRadius:"15px", width:{xs:"18rem" , md:"22rem", lg:'22rem'}}} src="./eezib/alice/Starbucks.jpg" />,
      <Box component='img' onClick={() => navigate(`/categories/restaurants`, { state: { data: [{ "value": 'restaurants', "token": tkn, "id": id }] } })} sx={{borderRadius:"15px", width:{xs:"18rem" , md:"22rem", lg:'22rem'}}} src="./eezib/alice/BASKINROBBINS.jpg" />,
    ]

  const responsive = {
      0: {
          items: 1,
      },
      350:{
        items:1,
      },
      750:{
        items:2,
      },
      1028:{
        items:3 ,
       itemsFit: 'contain',

      },

      
      1024: {
        items: 3,
        itemsFit: 'contain',
      },
      1129:{
        items:3,
      },
      
    }

    const closeLoginDialog =() => {
      setLoginDialog(false)
}

const handleLogouFunc = () => {
  // navigate("/" , {state : {login : null}});

  window.history.replaceState({}, '')
  Cookies.remove("voucherDemoAuth");
  Cookies.remove("voucherDemoName");
  Cookies.remove("voucherDemoUserId");

  window.location.reload();

}

    async function getData(){
      setLoading(true)
      try{
          // const api = env.REACT_APP_UAPI_URL;
          // const apiUrl = `${api}/api/login`;
          const api = env.REACT_APP_UATPI_URL;
          const apiUrl = `${api}/login`;
          const method = "POST";
          // const bodyData = {"email":userName , "password":password}    
          const bodyData = {"phone_no":userName , "password":password}    
          
          const json = await ApiFile({"apiUrl":apiUrl , "method":method, "bodyData":bodyData });

         console.log(json)

          setLoading(false);

          if(json.StatusCode === 200 ){
            
            setHandleSuccess(true);
            setJsonSuccess(json.message);


            Cookies.set("voucherDemoAuth",json.Message.access_token);
            Cookies.set("voucherDemoName",json.Message.user.name);
            Cookies.set("voucherDemoUserId",json.Message.user.id);  

            setUName(json.data.user.name);

            setTimeout(() => {
              setLoginDialog(false);
            },[500]);

          }
 
          else if(json.status === "error" ){
            setLoading(false)
             // window.alert("invalid credentials");
              setHandleError(true);
              setJsonError(json.message);
          }     
      }catch(err){
          // window.alert(err)         
          console.error("error : ",err);

          setHandleError(true);
          setJsonError(err);
      }
    }


    console.log("u name : ",u_name)

    const handleUserName =(event) => {
      const userCred = event.target.value;
      if (/^\d{0,10}$/.test(userCred)) {
        setUserName(userCred);
      }
    }

    const menuData = useSelector((state) => state.auth.data);

    const handleMenuChange = (event) => {
      
      event.preventDefault() ;
      setSearchedText(event.target.value.toLowerCase())
      
        const respo = menuData?.filter(data => data.name.toLowerCase().includes(event.target.value.toLowerCase())  );
        {
          respo?.length > 0 ? setFilteredData(respo) : setFilteredData(null)
        }

        if(searchedText?.length <= 1){
          setFilteredData(null)
        }
        
    }

    function handleCrossButton(){
      setFilteredData(null);
      setSearchedText('');
    }

    const handleLogoutFunc = async() => {



      Cookies.remove("voucherDemoAuth");
      Cookies.remove("voucherDemoName");
      Cookies.remove("voucherDemoName");
      Cookies.remove("voucherDemoUserNumber");

      setTimeout(() => {
        setHandleSuccess(true);
        setJsonSuccess("Logout Successfully..");
        // window.location.replace(env.REACT_APP_UAPI_URL, {replace:true});
        window.location.replace(env.REACT_APP_UATPI_URL, {replace:true});
      },500)


      setTimeout(() => {
        window.close();
      },1200)
    }

    
    const handleRedirection = async () => {

      // const accessToken = Cookies.get("demoCAuth");
       // document.cookie = `demoCAuth=${accessToken}; path=/; secure; samesite=strict;`;

      // if(process.env.NODE_ENV === "production"){
      //   window.open(`${process.env.REACT_APP_UAPI_URL}/eezib?home=${json.data}`, ""); 
      // }else{
      //   window.open(`http://192.168.0.191:3001/eezib?home=${json.data}`,"");
      // }
      if(process.env.NODE_ENV === "production"){
        window.open(`${process.env.REACT_APP_UAPI_URL}/ui/validateUser`, "_self"); 
      }else{
        window.open(`http://localhost:3000/ui/validateUser`,"_self");
        // window.open(`http://192.168.0.191:3001/ui/validateUser`,"_self");
      }


      // window.location.reload();
  
  }


  return (
    <Box className="landing" >
      <Box >


            <AmazingOffer/>

      <AppBar position='sticky' className={scrolling ? 'scrolling' : null} style={appBarStyle} >
          <Toolbar sx={{ display:'flex', alignItems:'center', justifyContent:'space-between' }} >

            <Box sx={{ width: { lg: "10rem", xs: "8rem" } ,padding: { lg: "0.6rem 0rem 0.6rem 0rem", xs: "0.3rem 0rem 0.3rem 0rem" } }} component='img' onClick={() => window.location.replace(env.REACT_APP_UAPI_URL)} src={imageSource}  />

            <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', gap:1 }} >
            <TextField value={searchedText} placeholder='search product..' autoComplete='off' onChange={handleMenuChange} InputProps={{ startAdornment:( <InputAdornment> <SearchIcon sx={{ marginRight:2 }} /> </InputAdornment> ), endAdornment:(  <InputAdornment> { filteredData ? <CloseIcon onClick={(e) => handleCrossButton() } sx={{ cursor:'pointer' }} /> : null }  </InputAdornment>) }} size='small' inputProps={{ style:{ fontFamily:'montserrat', fontWeight:400 } }}  />            
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', borderRadius:3, }} id="cursor" >
              {
                !u_name ?
                //  <Button onClick={() => window.location.replace(`${process.env.REACT_APP_UAPI_URL}/ui`) } variant='outlined' sx={{fontFamily:'montserrat'}} >Login</Button>
                <Button onClick={() => window.location.replace(`${process.env.REACT_APP_UATPI_URL}/login`) } variant='outlined' sx={{fontFamily:'montserrat'}} >Login</Button>
                  :
                 <FormControl size='small' sx={{ width:{lg:"12rem" , xs:"7rem"} }}>
                <InputLabel id="demo-simple-select-label" sx={{ fontFamily: "montserrat", fontWeight: 500 }}> {!u_name ? "Guest" : u_name} </InputLabel>
                <Select
                  label={!u_name ? "Guest" : u_name}
                >
                  <MenuItem onClick={() => setDialog(true)} sx={{fontFamily:'montserrat' , fontWeight:500}} >Voucher Categories</MenuItem> 
                  <MenuItem onClick={() => handleRedirection()} >Cards</MenuItem>
                  <MenuItem sx={{fontFamily:'montserrat' , fontWeight:500}} onClick={() => handleLogoutFunc() } > <LogoutIcon sx={{color:"#424242"}} />&nbsp; Logout  </MenuItem>
                </Select>
              </FormControl>
              }

            </Box>
          </Toolbar>
        </AppBar>

        <Box sx={{ display:'flex', justifyContent:'center', maxHeighteight:'30vh', overflow:'hidden', flexGrow:1, borderRadius:5, marginTop:"0.8rem" }} >
          
          { filteredData?.length >= 1 && filteredData !== null ?
                <Box sx={{ width:'20rem',position:"fixed", zIndex:2, backgroundColor:'#ebebeb', display:'flex', flexDirection:'column',p:2, borderRadius:3 }} >
                 {
                        filteredData?.slice(0,5).map((row) => (
                          <>
                          <Typography onClick={() => navigate("/voucher", {state : { data : { 'row': row, "auth":tkn, "id":id, "name":row.name  }}})} sx={{ textAlign:'left', cursor:"pointer", fontFamily:"montserrat", fontWeight:500 }} >{row.name}</Typography> <Divider variant='middle' component='ul' sx={{ margin:1 }} />
                          </>
                        ))
                      }
              </Box> : null
          }

        
          {/* {
            filteredData?.map((data) => {
              return (
                <Typography>{data.name}</Typography>
              )
            })
          } */}
        </Box>

        <Box sx={{margin:'1.5rem' , textAlign:'center' }} >

          <AliceCarousel
          mouseTracking
           items={items} 
          autoPlay
          animationDuration={800}  
          infinite
          lazyLoading
          renderNextButton={true}
          responsive={responsive}
          disableButtonsControls
          autoPlayInterval={1300} />      
      </Box>

        <LandingMode qrCode={ qrCode } />
        {/* <LandingMode /> */}
      </Box>

      


      <Dialog
        open={dialog}
        fullScreen
        // onMouseEnter={dialog}
        // onMouseLeave={handleClose}
        sx={{ top: 0 }}
      > 
          <AppBar position='sticky' className='navbar' >
            <Toolbar sx={{  display:'flex', alignItems:'center', justifyContent:'space-between' }} >
              <Box component='img' sx={{ width:"7rem" }} src={imageSource} />

            <Typography sx={{ fontFamily:'Poppins', fontWeight:400 , fontSize:"1.3rem", textTransform:'capitalize' }} > unveil your inner choice</Typography> 
            </Toolbar>
          </AppBar>
        <CancelIcon id="cross" onClick={handleClose} sx={{ position: "fixed", marginLeft: "auto", color: '#e05c53', cursor: 'pointer', top:"5rem", right:"2rem" }} />

        {/* <Box sx={{ display: 'grid', marginTop: "1rem", gridTemplateColumns: { lg: "repeat(4,1fr)", sm: "repeat(2,1fr)", md: "repeat(3,1fr)" }, gridColumnGap: { lg: '2.5rem', sm: "2rem", md: '4rem', xs: '1rem' }, gridRowGap: '0.5rem' }} >
          {
            data?.map((row) => {
              return (
                <Typography sx={{ cursor: 'pointer', fontFamily: 'montserrat' }} onClick={() => navigate(`/categories/${row.value}`, { state: { data: [{ "value": row.value, "token": tkn, "id": id }] } })} >{row.value}</Typography>
              ) })
          }
          </Box> */}

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'column' }}  >

        <Typography sx={{ fontFamily:'Poppins', fontWeight:600, fontSize:'2.5rem', color:"#58c8fc" }} > Unveil Your Inner Choice</Typography>
        <Typography sx={{ fontFamily:"montserrat", fontWeight:500, fontSize:"0.9rem", color:'#787878', textTransform:'capitalize' }} > Discover the perfect gift for every occasion with our wide range of gift card categories  </Typography>
          {/* <Divider  sx={{ width:"100%" }} /> */}

                <Box sx={{ marginTop: "2rem", display: 'grid', gridTemplateColumns: { lg: "repeat(3,1fr)", sm: "repeat(2,1fr)", md: "repeat(2,1fr)", xs: "repeat(2,1fr)" }, gridColumnGap: { lg: '4rem', sm: "2rem", md: '2rem', xs: '1.3rem' }, gridRowGap: '1rem' }} >

                { Array.isArray(data) && data?.map((row) => {
                        return (
                            <Box sx={{ marginTop: { lg: "3rem", md: "1.8rem", xs: "0rem" } }} >

                                <Paper onClick={() => navigate(`/categories/${row.value}`, { state: { data: [{ "value": row.value, "token": tkn, "id": id }] } })} className='scale' elevation={12} sx={{ width: { lg: 320, md: 280, xs: 150 }, height: { lg: 200, md: 170, xs: 90 }, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', borderRadius:5, cursor:'pointer' }} >
                                    <Box  component="img" src={row.image} sx={{ width: { lg: 320, md: 280, xs: 150 }, height: { lg: 200, md: 170, xs: 90 }, borderRadius: 2 }} />
                                </Paper>
                                <Divider sx={{ mt:"0.8rem" }} />
                                    <Typography sx={{ textAlign:'center', mt:0.5, fontFamily:'Poppins', fontWeight:400, color:"gray" }} >{row.name}</Typography>

                            </Box>
                        )
                    })
                    }
                  

                </Box>
                </Box>
          

        <DialogActions >
        </DialogActions>
      </Dialog>

            
 
        <Dialog
        open={loginDialog}
        onClose={closeLoginDialog}
        aria-describedby="alert-dialog-slide-description"
        sx={{borderRadius:4}}
        >

        <DialogContent  sx={{  backgroundColor:'#e3e3e3'}} >
            {/* <Box component='img' sx={{width:'5rem'}} src={image} /> */}
        <Box  sx={{ display:'flex' , alignItems:'center' ,justifyContent:'center' }}  >
          {
            loading ? <CircularProgress /> : 
            <Box sx={{ backgroundColor:"white", display:'flex' , alignItems:'center' ,justifyContent:'center', flexDirection:'column', borderRadius:2 , gap:"0.5rem" , backdropFilter:"blur(20px)", boxShadow:"0px 0px 10px 1px white" , padding:{lg:"1rem 2rem", xs:"1rem 0.5rem", sm:"2rem 1.5rem"}}} >
            {/* <Typography sx={{fontFamily:'montserrat' , fontWeight:700 , fontSize:"1.5rem" ,  marginTop:"1rem"}} >Login</Typography> */}
            
              <Box component='img' sx={{width:"5rem"}} src={eezib} /> 


                {/* <TextField variant='standard' size='medium' onChange={(e) => setUserName(e.target.value) } value={userName} InputProps={{ endAdornment:( <InputAdornment position='end' > <PersonIcon  /> </InputAdornment> ) }} className='loginInput' sx={{width:'16rem'  , fontFamily:'montserrat', fontSize:'0.4rem' , marginTop:'2rem'}}  placeholder=" username" /> */}
                {
                  env.REACT_APP_UAPI_URL === "https://b2cuat.eezib.in" || env.REACT_APP_UAPI_URL === "http://ankur.local/B2C_local/public" ? 
                  <TextField type='number' variant='standard' size='medium' onChange={ handleUserName } value={userName} InputProps={{ endAdornment:( <InputAdornment position='end' > <PersonIcon  /> </InputAdornment> ) }} className='loginInput' sx={{width:'16rem'  , fontFamily:'montserrat', fontSize:'0.4rem' , marginTop:'2rem'}}  placeholder=" Enter Number" /> : 
                  env.REACT_APP_UAPI_URL === "https://uat.eezib.in" ? 
                  <TextField variant='standard' size='medium' onChange={(e) => setUserName(e.target.value) } value={userName} InputProps={{ endAdornment:( <InputAdornment position='end' > <PersonIcon  /> </InputAdornment> ) }} className='loginInput' sx={{width:'16rem'  , fontFamily:'montserrat', fontSize:'0.4rem' , marginTop:'2rem'}}  placeholder=" Enter Number" /> : 
                  null
                }


                <TextField variant='standard' onChange={(e) => setPassword(e.target.value) } value={password} InputProps={{ endAdornment:( <InputAdornment position='end' > <PasswordIcon  /> </InputAdornment> ) }} className='loginInput' sx={{width:'16rem' , fontFamily:'montserrat', marginTop:'2rem'}}   placeholder=" password" />
                <Typography onClick={() => window.location.replace(`${env.REACT_APP_UAPI_URL}/password/reset`)} sx={{ fontFamily:'montserrat',fontSize:"0.7rem" , marginLeft:"auto", cursor:'pointer'}} >forget password ?</Typography>

                <Button onClick={() => getData()} fullWidth size='medium' variant='contained' sx={{fontFamily:'montserrat' , marginTop:'1.5rem' , fontWeight:500}} >submit</Button>

                <Typography onClick={() => window.location.replace(`${env.REACT_APP_UAPI_URL}/register`)} sx={{fontFamily:'montserrat' , fontSize:"14px" , fontWeight:500 , marginTop:'1rem' , cursor:'pointer' , color:"#8a8a8a"}} >not registered yet ?</Typography>
            </Box> 
          }
           </Box>
                
      
        </DialogContent>
    </Dialog>

    <Snackbar
      anchorOrigin={{  vertical:'top', horizontal:'right' }}
      open={handleSuccess}
      onClose={ successClose }
      autoHideDuration={1500}>
      <Alert severity="success" sx={{ width: '100%' }}>{jsonSuccess}</Alert>
    </Snackbar>

    <Snackbar
      anchorOrigin={{  vertical:'top', horizontal:'right' }}
      open={ handleError }
      onClose={ errorClose }
      autoHideDuration={1500} >
      <Alert severity="error" sx={{ width: '100%' }}>{ jsonError }</Alert>
    </Snackbar>

  
    </Box>
  )
}

export default Landing

//onMouseEnter={()=>setDialog(true)}