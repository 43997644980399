import React from 'react';
import env from "react-dotenv";
import { ApiFile } from './ApiHandler';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@mui/material';
import Cookies from 'js-cookie';


const WalletTopup = () => {

  const location = window.location.pathname;
  const navigate = useNavigate();

  const txnId = location.replace("/eezib/walletTopupProcess/","");

  console.log("transaction id : ",txnId);

  console.log(" wallet location : ",location);


  const walletTopupProcess = async() => {

    const api = env.REACT_APP_UAPI_URL;

    
    const apiUrl = `${api}/api/txnVerify`;
    console.log("api url : ",apiUrl )
    const method = "POST";
    const bodyData = { "TxnRefNo":txnId }
    const authToken = Cookies.get("voucherDemoAuth");

    
    const json = await ApiFile({"apiUrl":apiUrl , "method":method, "bodyData":bodyData, "authAccess":authToken });

    console.log("gateway : ",json);

    if(json){
      if(json.status === "success"){
        navigate('/voucher' , { state : { response : { "message":json.data.voucher_details, "popup": json.message }}})
      }

       else if(json.status === "error"){
        window.alert(json.message);
       navigate("/")
        }
    }

  }

    React.useEffect(() => {
      walletTopupProcess();
    },[txnId])

  return (
    <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', height:'100vh' }} >
      <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column' }} >
        <CircularProgress size="10rem" thickness="1"  />
        <Typography sx={{ mt:2, fontFamily:'montserrat', fontWeight:500, textTransform:'capitalize', fontSize:'1.2rem' }} > Your request is being processing, Please wait...  </Typography>
      </Box>
    </Box>
  )
}

export default WalletTopup