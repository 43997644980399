import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isAuthentication : false,
    user : null
}

const loginSlice = createSlice({
    name:'login',
    initialState,
    reducers:{
        login:(state, action) => {
            state.isAuthentication = true;
            state.user = action.payload;
        },
        logout:(state) => {
            state.isAuthentication = false;
        }
    }
})

export const {login, logout} = loginSlice.actions;
export default loginSlice.reducer;