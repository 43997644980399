import React from 'react';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import offer from "../images/amazingDiscount.png";


const AmazingOffer = () => {

    const [ visible , setVisible ] = React.useState(false);

    if(!visible){
        return null
    }

    // React.useEffect(() => {
    //     const timer = setTimeout(() => {
    //       setVisible(true);
    //     }, 3000);
    //     return () => clearTimeout(timer);
    //   }, []); 

  return (
    visible && (
    <Box sx={{  position:'fixed', marginTop:20, right:0, zIndex:999999999 }} >
    <CloseIcon onClick={() => setVisible(false)} sx={{ color:"gray", position:"absolute", cursor:'pointer' }} />
    <Box component="img" src={offer} sx={{ width:"15rem", }}  />
  </Box>)  )
}

export default AmazingOffer