import { Accordion, AccordionDetails, AccordionSummary, Alert, AppBar, Backdrop, Box, Breadcrumbs, Button, CircularProgress, Divider, Fade, FormControl, Input, InputAdornment, InputLabel, LinearProgress, Link, MenuItem, OutlinedInput, Paper, Select, Snackbar, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import DownloadIcon from '@mui/icons-material/Download';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import image from "../images/eezib2.png";
import eezib from "../images/eezib.png"
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import env from "react-dotenv";
import Slide from '@mui/material/Slide';
import PersonIcon from '@mui/icons-material/Person';
import PasswordIcon from '@mui/icons-material/Password';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useDispatch, useSelector } from 'react-redux';
import { orderSlice } from '../redux/order';
import { login } from '../redux/userAuth';
import Cookies from 'js-cookie';
import { ApiFile } from './ApiHandler';
import lowbalanceImg from "../images/lowBalance.jpg";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Radio from '@mui/material/Radio';
import eeLogo from "../images/ee.png";
import CloseIcon from '@mui/icons-material/Close';
import Menu from '@mui/material/Menu';
import Footer from './Footer';
import cardIcon from "../images/pgIcon.png";
import pgImage from "../images/pgIMage.png";
import qrIcon from "../images/qrCode.png"
import Checkbox from '@mui/material/Checkbox';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import orderImage from "../images/orderCompleted.jpg";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import confetti from 'canvas-confetti';
import upiImg from "../images/UPI.webp";
import bupi from "../images/BHIM.webp";
import redeem from "../images/redeem.webp";
import coins from "../images/coin.gif";
import coinImage from "../images/coinImage.png";
function Voucher() {

    const [data, setData] = React.useState([]);
    const [menuData, setMenuData] = React.useState();
    const [denomination, setSelectedMenu] = React.useState("");
    const [quantity, setQuantity] = React.useState('');
    const [user, setUser] = React.useState([]);
    const [user_id, setUserId] = React.useState("");
    const [jsonData, setJsonData] = React.useState(null);
    const [respo, setRespo] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [totalQuantity, setTotalQuantity] = React.useState('')
    const [fee, setFee] = React.useState('');
    const [value, setValue] = React.useState(false);
    const [totalAmount, setTotalAmount] = React.useState("");
    const [newOpen, setNewOpen] = React.useState(false);
    const [discountMargin, setDiscountMargin] = React.useState("");
    const [discountNum, setDiscountNum] = React.useState("");
    const [totalDiscount, setTotalDiscount] = React.useState("");
    const [errormsg, setErrormsg] = React.useState('')
    const [progress, setProgress] = React.useState(false);
    const [progress2, setProgress2] = React.useState(false);
    const [loginDialog, setLoginDialog] = React.useState(false);
    const [userName, setUserName] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [loading, setLoading] = React.useState(false)
    const [feeAmount, setFeeAmount] = React.useState('');
    const [maxLimit, setMaxLimit] = React.useState('');
    const [error, setError] = React.useState('');
    const [name, setName] = React.useState("");
    const [prog, setProg] = React.useState(false);
    const [qrWallet, setQrWallet] = React.useState("");

    const [subCategory, setsubCategory] = React.useState("");
    const [prodName, setProdName] = React.useState("");

    const [qrName, setQrName] = React.useState("");
    const [qrNumber, setQrNumber] = React.useState("");

    const [handleSuccess, setHandleSuccess] = React.useState(false);
    const [jsonSuccess, setJsonSuccess] = React.useState("");

    const [handleError, setHandleError] = React.useState(false);
    const [jsonError, setJsonError] = React.useState("");

    const [orderDetails, setorderDetails] = React.useState();

    const [lowbalance, setLowBalance] = React.useState(false)

    const [lowBalanceData, setLowbalanceData] = React.useState();

    const [pgUrl, setPgUrl] = React.useState('');
    const [openUrlDialogue, setOpenUrlDialogue] = React.useState(false);

    const [selectedValue, setSelectedValue] = React.useState('');

    const [anchorEl, setAnchorEl] = React.useState(null);

    const [qrCode, setQrCode] = React.useState('');
    const [openQr, setOpenQr] = React.useState(false);

    const [proceedCoin, setProceedCoin] = React.useState(0);
    const [init, setInit] = React.useState(false);
    const [openText, setOpenText] = React.useState(false);
    const [denomiValue, setDenomiValue] = React.useState("");

    const [warningOpen, setWarningOpen] = React.useState(false);
    const [warningText, setWarningText] = React.useState("");

    const [redemAmount, setRedemAmount] = React.useState("");

    // console.log(" *** proceed coins ***** : ",proceedCoin);

    const openMenu = Boolean(anchorEl);

    const [seconds, setSeconds] = React.useState(300);
    const [isActive, setIsActive] = React.useState(false);


    const [progress3, setProgress3] = React.useState(false);
    const [successDialogue, setSuccessDialogue] = React.useState(false);

    const [userDiscount, setUserDiscount] = React.useState("");
    const [redeemCoins, setRedeemCoins] = React.useState("");
    const [openRedeem, setOpenRedeem] = React.useState(false);

    const [redemProg, setRedemProg] = React.useState(false);
    const [coinValue, setCoinvalue] = React.useState("")

    const handleRedeemClose = () => {
        setOpenRedeem(false);
        setRedemAmount("")
    }

    const handleRedemAmount = (e) => {
        setRedemAmount(e.target.value)
    }

    const warningclose = () => {
        setWarningOpen(false);
    }

    useEffect(() => {
        initParticlesEngine(async (engine) => {
            // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
            // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
            // starting from v2 you can add only the features you need reducing the bundle size
            //await loadAll(engine);
            //await loadFull(engine);
            await loadSlim(engine);
            //await loadBasic(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    const handleSDialogue = () => {

        const end = Date.now() + 2 * 1000; // 15 seconds
        const colors = ["#57bcff", "#ffffff"];

        // Define the continuous confetti animation
        (function frame() {
            confetti({
                particleCount: 2,
                angle: 60,
                spread: 55,
                origin: { x: 0 },
                colors: colors,
                zIndex: 9999,
            });

            confetti({
                particleCount: 2,
                angle: 120,
                spread: 55,
                origin: { x: 1 },
                colors: colors,
                zIndex: 9999,
            });

            if (Date.now() < end) {
                requestAnimationFrame(frame);
            }
        })();

        setTimeout(() => {
            setSuccessDialogue(true);
        }, 0)
    }

    const handleSuccessD = () => {
        setSuccessDialogue(false);
    }

    const handlebackClose = () => {
        setProgress3(false);
    }


    React.useEffect(() => {
        let interval = null;

        if (isActive && seconds > 0) {
            interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
        } else if (seconds === 0) {
            // Stop everything when the timer reaches zero
            setIsActive(false);
            setOpenQr(false);
        }

        // Cleanup the interval when the component unmounts or when the timer ends
        return () => clearInterval(interval);
    }, [isActive, seconds]);


    // Optional: Ensure API is not called if the timer ends during an API call
    React.useEffect(() => {
        if (seconds === 0) {
            // Cleanup any remaining intervals for the API call
            setIsActive(false);
            setOpenQr(false);
        }
    }, [seconds]);

    // React.useEffect(() => {
    //     if(seconds === 0){
    //         // handleWalletRequest() 
    //         setIsActive(false);
    //         setOpenQr(false)
    //         // setSeconds(300);

    //            }
    // },[seconds])

    const startTimer = () => {
        setIsActive(true);
    };

    const resetTimer = () => {
        setSeconds(30); // Reset the timer to 30 seconds
        setIsActive(false); // Stop the timer
    };

    const handleOpenQr = async () => {
        setOpenQr(false);
        setTimeout(() => {
            window.location.reload();
        }, 200)
    }

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };



    const handleChange = (event) => {
        const value = event.target.value;
        setSelectedValue(prevValue => (prevValue === value ? '' : value));
        // setSelectedValue(event.target.value);
    };

    const handleCheck = (event) => {
        const check = event.target.checked;
        if (check) {
            setProceedCoin(1)
        } else {
            setProceedCoin(0);
        }
    }

    const successClose = () => {
        setHandleSuccess(false);
    }

    const errorClose = () => {
        setHandleError(false);
    }



    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userAuth = Cookies.get("voucherDemoAuth");

    const { item, isLoading, isError } = useSelector((state) => state.order);
    // const orderData = useSelector((state) => state.order.data)
    // const orderMsg = useSelector((state) => state.order.data.msg)


    React.useEffect(() => {

        if (item) {
            if (item.error) {
                setError(item.error)
            }
        }

    }, [item])



    const loginDetail = useSelector((state) => state.login);

    React.useEffect(() => {


        if (location.state) {

            if (location.state?.data) {
                console.log("location data : ", location.state.data.row);
                const locData = location.state.data.row;

                const subCat = locData?.sub_cat_id;
                setsubCategory(subCat);

                const product = locData?.name
                setProdName(product);

                const zeroDenomi = location.state.data.row?.valueDenominations?.length;
                console.log("array length : ", zeroDenomi);
                if (zeroDenomi === 0) {
                    setOpenText(true);
                }
                else if (zeroDenomi !== 0) {
                    setOpenText(false)
                }

                const values = location.state?.data.row;

                setData([values])
                setLoading(true)
                const name = location.state.data.name;
                setName(name);

                const user = location.state.data.auth;
                const uid = location.state.data.id;
                const maxValue = values?.orderQuantityLimit;
                setMaxLimit(maxValue);

                setUser(user);
                setUserId(uid);
                setLoading(false);
            }

            else if (location.state.response) {
                const message = JSON.parse(location.state.response.message);
                const popup = location.state.response.popup;

                setData([message])
                setHandleSuccess(true);
                setJsonSuccess(popup);


            }

            if (location.state === null) {
                getIdData();

            }

        }

    }, []);


    //    console.log("location.State data : ", location.state, "  user : ",user);

    const handleClose = () => {
        setOpen(false);
        setNewOpen(false);
        window.location.reload();
    };





    const menuItemData = () => {
        try {
            return data.map((a) => {
                const properdata = a.valueDenominations;
                const data1 = properdata.split(",");
                setMenuData(data1)
                return data1

            })
        } catch (err) {
        }
    }

    const handleDenominationChange = (e) => {
        setSelectedMenu(e.target.value);
    }


    function handleAmount(e) {

        console.log("discount value : ", e);
        const discountValue = e === undefined ? 0 : e;
        try {

            return data?.map((e) => {
                console.log("value data : ", value);
                const discValue = !value ? openText ? quantity * denomiValue * (discountValue / 100) : quantity * denomination * (discountValue / 100) : openText ? totalQuantity * denomiValue * (discountValue / 100) : totalQuantity * denomination * (discountValue / 100);
                setDiscountMargin(discValue);
                // const total = quantity * denomination - discValue;
                const total0 = !value ? openText ? quantity * denomiValue - discValue : quantity * denomination - discValue : openText ? totalQuantity * denomiValue - discValue : totalQuantity * denomination - discValue;
                const increasedAmount = (total0 * e.fee) / 100;
                setFee(e.fee)
                setFeeAmount(increasedAmount)

                const total = total0 + increasedAmount;
                console.log("total amount : ", Math.round(total * 100) / 100)?.toFixed(2);
                setTotalAmount((Math.round(total * 100) / 100).toFixed(2))
                setDiscountNum(e.discount);
                setOpen(true);

            })

        } catch (err) {
            console.log("hanlde amount error : ", err)

        }


    }

    React.useEffect(() => {

        menuItemData()
        window.scroll(0, 0)

        setTimeout(() => {
        }, 1500)

    }, [data])


    // *************************************** API FOR MAKING ORDER PURCHASE **********************************


    async function purchaseOrder() {




        if (openText && denomiValue < location.state.data?.row?.minValue) {
            setHandleError(true);
            setJsonError(`Denomination can't be less then ${location.state.data?.row?.minValue}`);
            return;
        }

        else if (quantity === "" && totalQuantity === "") {
            setHandleError(true);
            setJsonError(`Can't Proceed Without Quantity`);
            return;
        }

        else {
            getDiscount();
        }

    }


    const getDiscount = async () => {


        const data1 = data?.map(x => x.productId);
        const productID = data1[0];
        const productId = String(productID);
        const method = "GET";

        const api = env.REACT_APP_UAPI_URL;
        // https://uat.eezib.in/api/GetProductDiscount/14

        const b2bApiUrl = `${api}/api/GetProductDiscount/${productId}`;


        const apiUrl = `${api}/api/fetchDiscount/${productId}`;
        const authToken = Cookies.get("voucherDemoAuth");
        const json = await ApiFile({ "apiUrl": process.env.REACT_APP_UAPI_URL === "https://b2c.eezib.in" || process.env.REACT_APP_UAPI_URL === "https://b2cuat.eezib.in" ? apiUrl : b2bApiUrl, "method": method, "authAccess": authToken });

        if (json) {
            if (json.status === "success") {
                const discount = json.data.discount
                setUserDiscount(discount);
                handleAmount(parseFloat(discount));

                // checkBalance()

            }
            if (json.status === "error") {
                setHandleError(true);
                setJsonError(json.message);
                setLowbalanceData("");
                setLowBalance(false);
            }
        }
    }


    const checkBalance = async () => {

        const data1 = data?.map(x => x.productId);
        const productID = data1[0];
        const productId = String(productID);


        setProgress(true);
        const userIde = Cookies.get("voucherDemoUserId");

        const api = env.REACT_APP_UAPI_URL;
        const apiUrl = `${api}/api/orderInitiate`;
        const bodyData = { "user_id": userIde, "productId": productId, "json": jsonData, "quantity": value ? totalQuantity : quantity, "denomination": openText ? denomiValue : denomination, "discount_amt": discountMargin ? discountMargin : 0, "discount": userDiscount ? userDiscount : 0, "final_amt": totalAmount, "fees": fee, "feeAmount": feeAmount, "reqType": "voucher", "cdID": "", "subcategory": subCategory, "productName": prodName }
        const authToken = Cookies.get("voucherDemoAuth");

        const method = "POST";

        const json = await ApiFile({ "apiUrl": apiUrl, "method": method, "bodyData": bodyData, "authAccess": authToken });


        setProgress(false);

        if (json) {
            if (json.status === "success") {

                if (process.env.REACT_APP_UAPI_URL === "https://b2c.eezib.in" || process.env.REACT_APP_UAPI_URL === "https://b2cuat.eezib.in") {
                    if (json?.data?.order_eligible?.status === "success") {
                        if (json?.data?.order_eligible?.data.orderdetails.is_order === 1) {
                            setLowBalance(true);
                            setLowbalanceData(json.data);
                            setOpen(true);
                        } else if (json?.data?.order_eligible?.data.orderdetails.is_order === 0) {
                            setHandleError(true);
                            setJsonError(json?.data?.order_eligible?.message)
                            setOpen(true);
                        }
                    }
                }

                else if (process.env.REACT_APP_UAPI_URL === "https://client.eezib.in" || process.env.REACT_APP_UAPI_URL === "https://uat.eezib.in") {
                    setLowBalance(true);
                    setLowbalanceData(json.data);
                    setOpen(true);
                }


                if (json?.data?.order_eligible?.status === "error") {
                    setHandleError(true);
                    setJsonError(json?.data?.order_eligible?.message)
                    setOpen(false);
                }

            }

            if (json.status === "error") {
                setHandleError(true);
                setJsonError(json.message)
                setLowBalance(false);
                setOpen(false);
                if (process.env.REACT_APP_UAPI_URL === "https://b2c.eezib.in" || process.env.REACT_APP_UAPI_URL === "https://b2cuat.eezib.in") {
                    setLowBalance(true);
                    setLowbalanceData(json.data)
                } else {
                    return;
                }
            }
        }
    }




    const placeOrder = async () => {
        setOpen(false)
        setProgress(true)

        try {

            const data1 = data?.map(x => x.productId);
            const productID = data1[0];
            const productId = String(productID);

            const userIde = Cookies.get("voucherDemoUserId");

            const userDetails = { "user_id": userIde, "productId": productId, "json": jsonData, "quantity": value ? totalQuantity : quantity, "denomination": denomination, "discount_amt": discountMargin ? discountMargin : 0, "discount": discountNum ? discountNum : 0, "final_amt": totalAmount, "fees": fee, "feeAmount": feeAmount }
            //  dispatch(orderSlice({ "userDetails":userDetails }));

            const url = env.REACT_APP_UAPI_URL;


            //    const api = await fetch(`${url}/eezibapi/order`, {
            //     method:"POST",
            //     headers:{
            //         "Content-Type":'application/json',
            //         'Authorization':`Bearer ${userAuth}`
            //     },
            //     body:JSON.stringify(userDetails)
            // })

            // const json = await api.json();

            const api = env.REACT_APP_UAPI_URL;
            // const apiUrl = `${api}/eezibapi/order`;
            const apiUrl = `${api}/api/eezibapi/order`;
            const method = "POST";

            // const json = await ApiFile({"apiUrl":apiUrl , "method":method, "bodyData":userDetails, "authAccess":userAuth });
            const json = await ApiFile({ "apiUrl": apiUrl, "method": method, "bodyData": userDetails, "authAccess": userAuth });


            setProgress(false)


            if (json) {
                if (json.status === "success") {
                    setOpen(false);
                    setNewOpen(true);

                    setNewOpen(true)

                    setHandleSuccess(true);
                    setJsonSuccess(json.message);

                    setorderDetails(json.data);
                }

                if (json.status === "error") {


                    if (json.message === "Insufficient Balance Rejected by system") {
                        // setLowBalance(true);
                        checkBalance()
                    }

                    setHandleError(true);
                    setJsonError(json.message);

                    setTimeout(() => {
                        //window.location.reload();
                    }, 1200)
                }
            }

        } catch (error) {
            // window.alert("sorry, an error has been occurred ");
            // navigate("/eezib")
        }

    }

    // *************************************** DOWNLOAD EXCEL LOGIC *******************************************


    const handleDownload = () => {
        const data = [
            ['name', 'contact', 'email', 'quantity'],

        ];

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(data);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        const excelBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        saveAs(blob, 'sample.xlsx');
    };


    // ************************************************** VALIDATING EXCEL LOGIC ******************************************

    const validateExcelContent = (base64Data, fileInput) => {
        const data = atob(base64Data);
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        let isValid = true;
        let sumQuantity = 0;
        let errorMessages = [];

        const expectedHeaders = ['name', 'contact', 'email', 'quantity'];
        const headers = Object.keys(sheet)
            .filter((key) => /^[A-Z]+1$/.test(key))
            .map((key) => sheet[key].v);

        // Normalize header comparison
        const normalizeHeader = (header) => header.trim().toLowerCase();
        const normalizedHeaders = headers.map(normalizeHeader);
        const expectedNormalizedHeaders = expectedHeaders.map(normalizeHeader);

        if (JSON.stringify(normalizedHeaders) !== JSON.stringify(expectedNormalizedHeaders)) {
            errorMessages.push('Invalid headers in the uploaded Excel file.');
            isValid = false;
        }

        const seenContacts = new Set();

        // Start from row 2 (skipping headers), loop through all rows until the end
        let rowIndex = 2;
        while (sheet[`A${rowIndex}`] !== undefined) {  // Check if the row exists by looking at column A
            const nameCell = sheet[`A${rowIndex}`] ? sheet[`A${rowIndex}`].v : '';
            const contactCell = sheet[`B${rowIndex}`] ? sheet[`B${rowIndex}`].v : '';
            const emailCell = sheet[`C${rowIndex}`] ? sheet[`C${rowIndex}`].v : '';
            const quantityCell = sheet[`D${rowIndex}`] ? sheet[`D${rowIndex}`].v : '';

            // Validate contact
            if (!/^\d{10}$/.test(contactCell)) {
                errorMessages.push(`Invalid contact number in row ${rowIndex}. It must be 10 digits.`);
                isValid = false;
            }

            // Check for duplicates
            //   if (seenContacts.has(contactCell)) {
            //     errorMessages.push(`Duplicate contact number found in row ${rowIndex}.`);
            //     isValid = false;
            //   }

            seenContacts.add(contactCell);

            // Validate email
            if (!validateEmail(emailCell)) {
                errorMessages.push(`Invalid email format in row ${rowIndex}.`);
                isValid = false;
            }

            // Validate quantity
            if (!Number.isInteger(quantityCell) || quantityCell > maxLimit || quantityCell < 0) {
                errorMessages.push(`Invalid quantity in row ${rowIndex}. Must be a positive number and not exceed ${maxLimit}.`);
                isValid = false;
            }

            sumQuantity += quantityCell;

            // Move to the next row
            rowIndex++;
        }

        // If validation failed, show errors and clear the file input
        if (!isValid) {
            alert(errorMessages.join('\n'));
            fileInput.value = null;  // Clear the file input
            setValue(false);
            return;  // Stop further execution if there are errors
        }

        // If everything is valid, proceed with further actions
        alert('Excel uploaded successfully.');
        setValue(true);
        setTotalQuantity(sumQuantity);
    };





    const validateEmail = (email) => {
        // Simple email validation regex
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return emailRegex.test(email);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            const base64Data = e.target.result.split(',')[1];
            setJsonData(base64Data);
            validateExcelContent(base64Data, event.target);  // Pass the file input element
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const openLoginHandle = () => {
        //setLoginDialog(true)

        window.location.replace(`${env.REACT_APP_UAPI_URL}/login`);
    }

    const closeLoginDialog = () => {
        setLoginDialog(false)
    }

    async function getData() {
        setLoading(true)
        try {
            const api = env.REACT_APP_UAPI_URL;
            const apiUrl = `${api}/api/login`;
            const method = "POST";
            // const bodyData = {"email":userName , "password":password} 

            const bodyData = { "phone_no": userName, "password": password }


            const json = await ApiFile({ "apiUrl": apiUrl, "method": method, "bodyData": bodyData });


            setLoading(false);

            if (json.StatusCode === 200) {
                setHandleSuccess(true);
                setJsonSuccess(json.message);

                Cookies.set("voucherDemoAuth", json.Message.access_token);
                Cookies.set("voucherDemoName", json.Message.user.name);
                Cookies.set("voucherDemoUserId", json.Message.user.id);

                setTimeout(() => {
                    setLoginDialog(false);
                }, [500]);

            }

            else if (json.status === "error") {
                setLoading(false)
                // window.alert("invalid credentials");
                setHandleError(true);
                setJsonError(json.message);
            }
        } catch (err) {
            // window.alert(err)         
            console.error("error : ", err);

            setHandleError(true);
            setJsonError(err);
        }
    }

    async function validateQuantity(value) {
        if (value < 1 && value !== '') {
            window.alert("quantity should not be zero or negative ");
            setQuantity(1)
            // window.location.reload();
        }
        else if (value > 10) {
            window.alert("cannot exceed quantity by 10")
            setQuantity(10)

        }
        else {
            setQuantity(value)
        }
    }






    const getIdData = async () => {

        const urlData = window.location.pathname;

        const id = urlData.replace("/eezib/voucher/", "");

        const api = env.REACT_APP_UAPI_URL

        const fetchUrl = await fetch(`${api}/api/getVoucherDetails/${id}`);

        const json = await fetchUrl.json();



        if (json) {
            if (json.status === "success") {
                setData([json.data]);
            }
            if (json.status === 'failed') {
                setTimeout(() => {
                    navigate(-1);
                }, [])
            }
        }
    }

    //   React.useEffect(() => {
    //     getIdData()
    //   },[]);




    const jaiGateway = async () => {



        setProgress(true)

        const usrId = Cookies.get("voucherDemoUserId");
        const authToken = Cookies.get("voucherDemoAuth");


        const api = env.REACT_APP_UAPI_URL;
        const apiUrl = `${api}/api/b2c_gateway`;
        const method = "POST";
        const bodyData = { "user_id": usrId, "amount": lowBalanceData?.topup_amount ? lowBalanceData?.topup_amount : denomination, "order_id": lowBalanceData.order_id.toString() }

        const json = await ApiFile({ "apiUrl": apiUrl, "method": method, "bodyData": bodyData, "authAccess": authToken });


        setProgress(false)

        if (json) {
            if (json.status === "success") {
                setPgUrl(json.data?.url);
                setHandleSuccess(true);
                setJsonSuccess(json.message);
                setLowBalance(false);
                setOpenUrlDialogue(true)
            }

            if (json.status === "error") {
                setHandleError(true);
                setJsonError(json.message);
            }
        }

    }

    const handleWalletRequest = async () => {


        setProgress2(true);

        const userId = Cookies.get("voucherDemoUserId");

        const api = env.REACT_APP_UAPI_URL;
        const apiUrl = `${api}/api/order`;
        const method = "POST";
        const bodyData = { "user_id": userId.toString(), "order_id": lowBalanceData.order_id.toString(), "is_upi": selectedValue === "b" ? 1 : 0, "is_wallet": proceedCoin, "wallet_balance": lowBalanceData?.wallet_amount };
        const authToken = Cookies.get("voucherDemoAuth");

        const json = await ApiFile({ "apiUrl": apiUrl, "method": method, "bodyData": bodyData, "authAccess": authToken });

        setProgress2(false);


        if (json) {
            if (json.status === "success") {
                setHandleSuccess(true);
                setJsonSuccess(json.message);

                setLowBalance(false);
                handleSDialogue();

                setTimeout(() => {
                    setLowBalance(false);
                    setOpenQr(false)
                    setIsActive(false);
                    setOpen(false);
                    window.location.reload();
                }, 5000)
            }

            if (json.status === "error") {
                setHandleError(true);
                setJsonError(json.message);
                setLowBalance(false);
                setLowbalanceData("")

                setSelectedValue("");
                setOpen(false);
                console.log("expected eror : 886");
                return;
            }

            if (json.status === "pending") {
                setWarningOpen(true);
                setWarningText(json.message);
                setLowBalance(false);
                setLowbalanceData("")
                setSelectedValue("");
                setOpen(false);
            }
        }

    }

    const handlePgRequest = async () => {

        const userId = Cookies.get("voucherDemoUserId");

        const api = env.REACT_APP_UAPI_URL;
        const apiUrl = `${api}/api/eezibapi/order`;
        const method = "POST";
        const bodyData = { "user_id": userId.toString(), "order_id": lowBalanceData.order_id.toString() }
        // const bodyData = { "user_id":Cookies.get("voucherDemoUserId"), "amount":denomination }

        const json = await ApiFile({ "apiUrl": apiUrl, "method": method, "bodyData": bodyData });

    }

    const avgCharWidth = 10;

    const denominationStr = parseFloat(denomination)?.toFixed(2);

    const width = denominationStr.length * avgCharWidth;



    const valueString = !value ? parseFloat(denomination * quantity)?.toFixed(2) : parseFloat(denomination * totalQuantity).toFixed(2)

    const valueWidth = valueString.length * avgCharWidth

    const placeQrOrder = async (newBal) => {


        setOpenQr(false)
        setIsActive(false);
        setOpen(false);

        setProgress3(true);
        setProgress2(true);

        const userId = Cookies.get("voucherDemoUserId");

        const api = env.REACT_APP_UAPI_URL;
        const apiUrl = `${api}/api/order`;
        const method = "POST";
        const bodyData = { "user_id": userId.toString(), "order_id": lowBalanceData.order_id.toString(), "is_upi": selectedValue === "b" ? 1 : 0, "is_wallet": proceedCoin, "wallet_balance": lowBalanceData?.wallet_amount };
        const authToken = Cookies.get("voucherDemoAuth");

        // const bodyData = { "user_id":Cookies.get("voucherDemoUserId"), "amount":denomination }

        const json = await ApiFile({ "apiUrl": apiUrl, "method": method, "bodyData": bodyData, "authAccess": authToken });

        setProgress3(false);
        setProgress2(false);

        if (json) {
            if (json.status === "success") {

                setHandleSuccess(true);
                setJsonSuccess(json.message);
                setLowBalance(false);
                handleSDialogue();
                setTimeout(() => {
                    setLowBalance(false);
                    setOpenQr(false);
                    setIsActive(false);
                    setOpen(false);
                }, 5000)
            }

            if (json.status === "error") {
                setHandleError(true);
                setJsonError(json.message);
                console.log("order api called error  : 971")

            }


            if (json.status === "pending") {
                setWarningOpen(true);
                setWarningText(json.message);
                setLowBalance(false);
                setLowbalanceData("")
                setSelectedValue("");
                setOpen(false);
            }
        }

    }




    const handleWalletBalance = async () => {

        console.log("handle wallet balance called");

        const url = process.env.REACT_APP_UAPI_URL;
        const apiUrl = `${url}/api/b2cUserWallet`;
        const method = "GET";
        const authToken = Cookies.get("voucherDemoAuth");


        const json = await ApiFile({ "apiUrl": apiUrl, "method": method, "authAccess": authToken });

        if (json) {
            if (json.status === "success" && json.statuscode === 200) {
                const currentWallet = json.data?.walletbalance;

                if (parseFloat(currentWallet) >= parseFloat(lowBalanceData?.wallet_amount) + parseFloat(lowBalanceData?.order_amount)) {
                    placeQrOrder(json.data?.walletbalance);
                } else {
                    return;
                }
            }
            if (json.status === "error") {
                setHandleError(true);
                setJsonError(json.message);

                setLowBalance(false);
                setOpenQr(false)
                setIsActive(false);
                setOpen(false);
            }
        }
    }


    const handleQr = async (data) => {

        console.log("handle qr data code : ", data);
        // 0 == qr and 1 == qr and wallet
        const getQr = sessionStorage.getItem("voucherDemoQr");
        setQrCode(getQr);
        const userNumber = Cookies.get("userPhone");
        setQrName(userNumber);
        const userName = Cookies.get("userName");
        setQrName(userName);

        if (data === 0) {

            setOpenQr(true);
            setIsActive(true);
            // handleWalletBalance();
        }
        else if (data === 1) {
            setOpenQr(true);
            setIsActive(true);
        }
    }



    React.useEffect(() => {
        let apiInterval = null;
        if (openQr && isActive && selectedValue === 'c' && proceedCoin === 0) {
            // Initial API call
            handleWalletBalance();
            // Set up an interval to call the API every 7 seconds
            apiInterval = setInterval(() => {
                if (seconds > 0) {
                    handleWalletBalance();
                }
            }, 7000);
        }

        else if (openQr && isActive && selectedValue === 'c' && proceedCoin === 1) {
            // Initial API call
            handleProceed();
            // Set up an interval to call the API every 7 seconds
            apiInterval = setInterval(() => {
                if (seconds > 0) {
                    handleProceed();
                }
            }, 7000);
        }
        // Cleanup the interval when the component unmounts or when dependencies change
        return () => {
            if (apiInterval) {
                clearInterval(apiInterval);
            }
        };
    }, [openQr, isActive, selectedValue, proceedCoin]);



    const handleProceed = async () => {
        console.log("handle proceed called");
        const getQr = sessionStorage.getItem("voucherDemoQr");
        setQrCode(getQr);

        console.log("Is Active : ", isActive, "  open qr : ", openQr);

        const url = process.env.REACT_APP_UAPI_URL;
        const apiUrl = `${url}/api/b2cUserWallet`;
        const method = "GET";
        const authToken = Cookies.get("voucherDemoAuth");

        const json = await ApiFile({ "apiUrl": apiUrl, "method": method, "authAccess": authToken });

        if (json) {
            if (json.status === 'success' && json.statuscode === 200) {
                if (parseFloat(json.data?.walletbalance) >= parseFloat(lowBalanceData?.topup_amount) + parseFloat(lowBalanceData?.wallet_amount)) {
                    handleWalletRequest();
                } else {
                    return
                }
            }
            else if (json.status === "error") {
                setHandleError(true);
                setJsonError(json.message);
                setTimeout(() => {
                    setLowBalance(false);
                    setOpenQr(false)
                    setIsActive(false);
                    setOpen(false);
                }, 1200)
            }
        }
        else {
            window.alert("Re-Initiate order.")
            setLowBalance(false);
            setSelectedValue("");
        }
    }

    const handleExceptErr = async () => {
        setHandleError(true);
        setJsonError("Please choose valid payment option.");
    }

    const handleValueCancel = () => {
        setLowBalance(false);
        setOpen(false)
    }

    const handleDenomi = (e) => {
        const amount = e.target.value;
        const regex = /^\d+$/;
        const maxValue = location.state.data?.row?.maxValue

        if (amount > parseInt(maxValue)) {
            if (regex.test(amount)) {
                setHandleError(true);
                setJsonError(` Maximum Denomination Amount is ${location.state.data?.row?.maxValue}`);
                return;
            }
        } else {
            console.log(typeof (e.target.value));
            setDenomiValue(e.target.value);
        }
    }

    const handleRedeem = async () => {
        try {
            const url = process.env.REACT_APP_UAPI_URL;
            const apiUrl = `${url}/api/b2c_reward_wallet `;
            const method = "GET";
            const authToken = Cookies.get("voucherDemoAuth");

            const json = await ApiFile({ "apiUrl": apiUrl, "method": method, "authAccess": authToken });

            if (json) {
                if (json.status === "success") {
                    setRedeemCoins(json?.data?.rewardcoins);
                    setCoinvalue(json?.data?.multiple_factor)
                    setOpenRedeem(true);
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    const redemCoins = async () => {
        try {
            // setRedemProg(true)

            if (parseFloat(redeemCoins) < parseFloat(redemAmount)) {
                setHandleError(true);
                setJsonError("Not Enough Points to Avail Redemption.");
                return;
            }

            if (Number(redemAmount?.includes("."))) {
                setHandleError(true);
                setJsonError("Amount Cannot Be In Decimals.");
                return;
            }

            const minCoinRedem = 10;

            if (Number(redemAmount) < minCoinRedem) {
                setHandleError(true);
                setJsonError(`Minimum Redeem Criteria Consists ${minCoinRedem} Coins.`);
                return;
            }

            const url = process.env.REACT_APP_UAPI_URL;
            const apiUrl = `${url}/api/redeem_point`;
            const method = "POST";
            const authToken = Cookies.get("voucherDemoAuth");
            const bodyData = {
                "reward_points": parseInt(redemAmount)
            }

            const json = await ApiFile({ "apiUrl": apiUrl, "method": method, "authAccess": authToken, 'bodyData': bodyData });

            setRedemProg(false)

            if (json) {
                if (json.status === 'success') {
                    setHandleSuccess(true);
                    setJsonSuccess(json.message);
                    setRedemAmount("");
                    checkBalance()
                    setTimeout(() => {
                        setOpenRedeem(false);
                    }, 2000)
                }
                if (json.status === "error") {
                    setHandleError(true);
                    setJsonError(json.message);
                }
            }

        } catch (err) {

        }
    }

    return (
        <Box   >
            {
                loading ? <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: '30%' }} > <CircularProgress /> </Box> :

                    Array.isArray(data) && data?.map((row) => {
                        return (
                            <Box sx={{ backgroundColor: "#f0f0f0", minHeight: "100vh" }} >
                                <AppBar position='static' sx={{ background: "linear-gradient(20deg, rgb(0, 149, 255), rgb(30, 255, 240))" }} >
                                    <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
                                        <Box sx={{ display: "flex", alignItems: 'center', justifyContent: 'center' }} >
                                            <ArrowBackIosNewIcon sx={{ cursor: 'pointer', fontSize: { lg: "2rem", xs: "1.5rem" } }} onClick={() => navigate("/products")} />
                                            <Box component="img" onClick={() => navigate(-1)} sx={{ width: "8rem" }} src={image} />
                                        </Box>
                                        <Typography sx={{ fontFamily: 'montserrat', cursor: "pointer", fontWeight: 500, fontSize: { lg: "1.3rem", xs: "0.7rem" }, color: 'white', textShadow: "2px 2px 6px black" }} >{row?.name}</Typography>
                                    </Toolbar>
                                </AppBar>

                                <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center', flexDirection: { lg: "row", xs: "column" } }} >

                                    <Box sx={{ margin: { lg: "2rem", xs: "0rem" }, marginTop: { xs: "2rem", lg: "2rem" }, width: { lg: "40%", xs: "100%" }, display: 'flex', alignItems: 'center', flexDirection: 'column' }} >


                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                            <Typography sx={{ fontFamily: 'montserrat', fontWeight: 500 }} >Validity :&nbsp; </Typography>
                                            {/* <Typography sx={{ fontFamily: 'montserrat', fontWeight: 500 }} dangerouslySetInnerHTML={{ __html: row?.expiryAndValidity.replace("&nbsp;", "") }}></Typography> */}

                                        </Box>
                                        {
                                            row?.imageUrl !== null ?
                                                <Paper elevation={18} component='img' src={row?.imageUrl} sx={{ borderRadius: 3, marginTop: 1, marginBottom: "0.5rem", width: { xs: "20rem", lg: "23rem" } }} /> :
                                                <Paper key={row.name} className='scale' elevation={8} sx={{ overflow: "hidden", width: { lg: 270, md: 250, sm: 230, xs: 140 }, height: { lg: 170, md: 170, sm: 130, xs: 85 }, borderRadius: 3, background: '#f2e6e6', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'column' }} > <Typography sx={{ fontFamily: "Roboto", fontWeight: '600', fontStyle: 'italic', color: '#7d0606', textShadow: "0px 0px 2px #7d0606", fontSize: { lg: "1.4rem", sm: "1.1rem", xs: ".7rem" }, padding: 2.5 }} >{row.name}</Typography></Paper>
                                        }


                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} >

                                            <Accordion sx={{ width: { lg: "25rem", xs: "21rem" }, marginTop: "1.6rem" }} >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography sx={{ fontFamily: "montserrat", fontWeight: 500 }} >Description</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails sx={{ maxHeight: "200px", overflowY: "auto" }} >
                                                    <Typography sx={{ fontFamily: "montserrat", fontWeight: 500 }} dangerouslySetInnerHTML={{ __html: row?.description }} />
                                                </AccordionDetails>
                                            </Accordion>

                                            <Accordion sx={{ width: { lg: "25rem", xs: "21rem" }, marginTop: { lg: "1rem", xs: "0.9rem" } }} >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"

                                                >
                                                    <Typography sx={{ fontFamily: "montserrat", fontWeight: 500 }} >Terms and Conditions</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails sx={{ maxHeight: "200px", overflowY: "auto" }} >
                                                    <Typography sx={{ fontFamily: "montserrat", fontWeight: 500, margin: '0.8rem' }} dangerouslySetInnerHTML={{ __html: row?.termsAndConditionsInstructions }} />

                                                </AccordionDetails>
                                            </Accordion>

                                            <Accordion sx={{ width: { lg: "25rem", xs: "21rem" }, marginTop: { lg: "1rem", xs: "0.9rem" } }} >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography sx={{ fontFamily: "montserrat", fontWeight: 500 }} >Redemption Instructions</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails sx={{ maxHeight: "200px", overflowY: "auto" }} >
                                                    <Typography sx={{ fontFamily: "montserrat", fontWeight: 500, margin: '0.8rem' }} dangerouslySetInnerHTML={{ __html: row?.redemptionInstructions }} />

                                                </AccordionDetails>
                                            </Accordion>

                                        </Box>


                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '1rem', flexDirection: 'column' }} >

                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                                <Typography sx={{ fontFamily: "montserrat", fontWeight: 450, fontSize: "1rem" }} >Delivery : </Typography> &nbsp;
                                                <Typography sx={{ fontFamily: "montserrat", fontWeight: 450, fontSize: "1rem" }}  >{row.deliveryType}</Typography>
                                            </Box>
                                            &nbsp;
                                            <Box sx={{ marginTop: "-0.50rem" }}>
                                                {
                                                    data?.map((e) => {
                                                        return (
                                                            <Typography sx={{ fontFamily: 'montserrat', fontWeight: 500 }} >current discount : {e.discount}%</Typography>
                                                        )
                                                    })
                                                }
                                            </Box>
                                        </Box>


                                    </Box>

                                    <Box sx={{ height: 'auto', width: { lg: "60%", xs: "100%" }, display: 'flex', alignItems: 'center', flexDirection: 'column' }} >

                                        <Box sx={{ marginTop: { lg: "5rem", xs: "2rem" }, backgroundColor: 'white', display: 'flex', flexDirection: 'column', borderRadius: 3, gap: '2rem', padding: { lg: "2.5rem", xs: "0.9rem" } }} >



                                            {/* ******************************************************* DENOMINATION FIELD ******************************************************* */}

                                            {
                                                openText ? <Box>
                                                    <TextField type='number' value={denomiValue} onChange={handleDenomi} sx={{ width: "20rem" }} InputLabelProps={{ style: { fontFamily: "Poppins" } }} label="Enter Denomination" />
                                                    <Typography sx={{ fontFamily: "Poppins", fontSize: "0.7rem", textTransform: "capitalize", color: '#a3a3a3', textAlign: 'right' }} > order eligibility from {location.state.data.row?.minValue} - {location.state.data.row?.maxValue} </Typography>
                                                </Box> :
                                                    <FormControl sx={{ width: "20rem" }} >
                                                        <InputLabel id="demo-simple-select-label" sx={{ fontFamily: 'montserrat', fontWeight: 500 }} >Enter Denomination</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            // value={row.valueDenominations.split(",")}
                                                            label="Enter Denomination"
                                                            sx={{ fontFamily: 'montserrat', fontWeight: 500, }}
                                                            onChange={handleDenominationChange}
                                                            key={row.productId}

                                                        >
                                                            {
                                                                menuData?.map((e) => {
                                                                    return (
                                                                        <MenuItem sx={{ fontFamily: 'montserrat', fontWeight: 500 }} value={e} >{e}</MenuItem>
                                                                    )
                                                                })
                                                            }


                                                        </Select>
                                                    </FormControl>
                                            }




                                            {/* *********************************************************** QUANTITY FIELD *************************************************** */}

                                            {
                                                value ? <TextField InputLabelProps={{ style: { fontFamily: "Poppins" } }} type='number' label="Quantity" focused value={totalQuantity} inputProps={{ style: { fontFamily: 'montserrat', fontWeight: 500 } }} /> : <TextField InputLabelProps={{ style: { fontFamily: "Poppins" } }} InputProps={{
                                                    inputProps: { max: 10, min: 1 }
                                                }} label="Quantity" value={quantity} type='number' onChange={(e) => totalQuantity ? setQuantity('') : validateQuantity(e.target.value)} inputProps={{}} />
                                            }

                                            <Typography sx={{ fontFamily: 'montserrat', fontSize: "0.7rem", textAlign: 'right', fontWeight: 500, color: "#8a8a8a", marginTop: '-2rem' }} >max limit 10</Typography>

                                            {/* <TextField label="Quantity" onChange={(e) => setQuantity(e.target.value)} inputProps={{ style: { fontFamily: 'montserrat', fontWeight: 500 } }} /> */}

                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >

                                                {
                                                    row.usageType ? <Box sx={{ display: 'flex', float: 'left' }} >

                                                        <Typography sx={{ fontFamily: "montserrat", fontWeight: 450, fontSize: "1rem" }} >usage : </Typography>&nbsp;
                                                        <Typography sx={{ fontFamily: "montserrat", fontWeight: 500, color: 'blue' }} >{row.usageType}</Typography>
                                                    </Box> : null
                                                }


                                                <Box sx={{ display: 'flex', alignItems: 'center' }} >
                                                    <Typography sx={{ fontFamily: "montserrat", fontWeight: 450, fontSize: "1rem" }} >Fee :</Typography>&nbsp;
                                                    <Typography sx={{ fontFamily: "montserrat", fontWeight: 450, fontSize: "1rem", color: 'red' }} >{row.fee}%</Typography>
                                                </Box>




                                            </Box>



                                            < hr style={{ marginTop: "-0.5rem" }} />

                                            {/* {
                                menuData && quantity ?<Button onClick={() => navigate("/login")} variant='contained' sx={{fontFamily:'montserrat' , fontWeight:500 }} > Purchase </Button> : 
                                <Button disabled variant='contained' sx={{fontFamily:'montserrat' , fontWeight:500 }} > Purchase </Button>
                            } */}

                                            {
                                                userAuth ? denomination || denomiValue && !isNaN(quantity) && quantity || value && denomiValue ?
                                                    <Button onClick={() => process.env.REACT_APP_UAPI_URL === "https://uat.eezib.in" || process.env.REACT_APP_UAPI_URL === "https://client.eezib.in" ? purchaseOrder() : purchaseOrder()} variant='contained' sx={{ fontFamily: 'montserrat', fontWeight: 500 }} > Purchase @ ₹{value ? openText ? denomiValue * totalQuantity : denomination * totalQuantity : openText ? denomiValue * quantity : denomination * quantity}</Button>
                                                    : <Button disabled variant='contained' sx={{ fontFamily: 'montserrat', fontWeight: 500 }} > Purchase </Button> :
                                                    <Button onClick={() => openLoginHandle()} variant='contained' sx={{ fontFamily: 'montserrat', fontWeight: 500 }} > login </Button>
                                            }

                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', margin: '0.5rem' }} >
                                            {/* <Box {...getRootProps()} sx={{ cursor: 'pointer', margin: '0.5rem', padding: '12px', textAlign: 'center' }}>
                                            <input {...getInputProps()} accept=".xlsx" />
                                            {isDragActive ? (
                                                <Typography  >Drop the Excel file here...</Typography>
                                            ) : (
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "center", fontSize: { lg: "1rem", md: "0.7rem", xs: "0.8rem" } }} > <UploadFileIcon sx={{}} />click to drop excel </Box>
                                            )}
                                        </Box> */}


                                            <Box>

                                            </Box>

                                            {
                                                process.env.REACT_APP_UAPI_URL === "https://uat.eezib.in" || process.env.REACT_APP_UAPI_URL === "https://client.eezib.in" ? <>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                                        <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
                                                        {/* <button onClick={() => handleUpload(base64Content)} disabled={!base64Content}>
                                                    Upload File
                                                </button> */}
                                                    </Box>


                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '0.6rem' }} >
                                                        <DownloadIcon sx={{ color: '#066bd6' }} />
                                                        <Button size='small' sx={{ fontFamily: 'montserrat', fontSize: { lg: "0.9rem", md: "0.7rem", xs: "0.7rem" } }} onClick={handleDownload} >download sample</Button>
                                                    </Box>
                                                </> :
                                                    null
                                            }
                                        </Box>

                                    </Box>

                                </Box>

                                <Dialog
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"

                                >
                                    <DialogContent sx={{ padding: "2rem 2.5rem" }} >

                                        {
                                            data?.map((e) => {
                                                return (
                                                    <Box>
                                                        <Typography sx={{ fontFamily: 'montserrat', fontWeight: 500, fontSize: "1.2rem", textAlign: 'center' }} >Eezib Order Summary</Typography>
                                                        <Divider variant='middle' sx={{ margin: "13px 0px" }} />

                                                        <Box  >

                                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }} >


                                                                <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'center', flexDirection: 'column' }} >
                                                                    <Typography sx={{ fontFamily: "montserrat", fontWeight: 500, fontSize: { lg: "1rem", md: "1rem", sm: "0.9rem", xs: "0.8rem" } }} > Eezib Denomination  Value  <span style={{ color: "#b3b3b3" }} ></span>  <span style={{}} >  </span> </Typography>
                                                                    <Typography sx={{ fontFamily: "montserrat", fontWeight: 500, fontSize: "1rem" }} > Eezib Order Quantity  <span style={{ color: "#b3b3b3" }} ></span>  <span style={{}} >  </span> </Typography>
                                                                    {
                                                                        e.discount > 0 ?
                                                                            <Typography sx={{ fontFamily: "montserrat", fontWeight: 500, fontSize: "1rem" }} > Eezib Discount Rate  Value  <span style={{ color: "#b3b3b3" }} ></span>  <span style={{}} >  </span> </Typography>
                                                                            : null
                                                                    }
                                                                </Box>

                                                                <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'center', flexDirection: 'column' }} >
                                                                    <Typography sx={{ fontFamily: "montserrat", fontWeight: 500, fontSize: "1rem" }} >  : </Typography>
                                                                    <Typography sx={{ fontFamily: "montserrat", fontWeight: 500, fontSize: "1rem" }} >  : </Typography>
                                                                    {
                                                                        e.discount > 0 ?
                                                                            <Typography sx={{ fontFamily: "montserrat", fontWeight: 500, fontSize: "1rem" }} >  : </Typography>
                                                                            : null
                                                                    }
                                                                </Box>

                                                                <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'center', flexDirection: 'column' }} >
                                                                    <Box component="img" src={eeLogo} sx={{ width: "1rem", mt: 0.85, mb: 0.85 }} />
                                                                    <Box component="img" src={eeLogo} sx={{ width: "1rem", mt: 0.85, mb: 0.85 }} />
                                                                    {
                                                                        e.discount > 0 ?
                                                                            <Box component="img" src={eeLogo} sx={{ width: "1rem", mt: 0.85, mb: 0.85 }} />
                                                                            : null
                                                                    }
                                                                </Box>

                                                                <Box sx={{ display: 'flex', alignItems: 'right', justifyContent: 'right', flexDirection: 'column', width: "4rem" }} >
                                                                    <Typography sx={{ color: '#d62b2e', fontFamily: 'montserrat', fontWeight: 500, color: "#383838", textAlign: 'right', marginleft: 'auto' }} > {openText ? parseFloat(denomiValue)?.toFixed(2) : parseFloat(denomination)?.toFixed(2)} </Typography>
                                                                    <Typography sx={{ color: '#d62b2e', fontFamily: 'montserrat', fontWeight: 500, color: "#383838", textAlign: 'right', marginleft: 'auto' }} > {value ? parseFloat(totalQuantity).toFixed(2) : parseFloat(quantity).toFixed(2)}     </Typography>
                                                                    {
                                                                        e.discount > 0 ?
                                                                            <Typography sx={{ color: '#d62b2e', fontFamily: 'montserrat', fontWeight: 500, color: "#383838", textAlign: 'right', marginleft: 'auto' }} > {userDiscount}% </Typography>
                                                                            : null
                                                                    }

                                                                </Box>

                                                            </Box>
                                                            {/* <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center' }} >
                                                    <Typography sx={{ fontFamily:"montserrat", fontWeight:500, fontSize:"1rem" }} > Eezib Denomination  Value : <span style={{ color:"#b3b3b3" }} ></span>  <span style={{ }} >  </span> </Typography>
                                                        &nbsp;
                                                    <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center' }} >
                                                        <Box component="img" src={eeLogo} sx={{ width:"1rem" }} />
                                                        &nbsp;
                                                        <Typography sx={{ color:'#d62b2e', fontFamily:'montserrat', fontWeight:500 , color:"#383838", width:`${ width }px`, textAlign:'right'  }} > { openText ? parseFloat(denomiValue)?.toFixed(2)  : parseFloat(denomination)?.toFixed(2)} </Typography>
                                                    </Box>
                                                    </Box> */}

                                                            {/* <Typography sx={{ fontFamily:'montserrat' , fontWeight:500 , fontSize:"1.1rem" }} > * Denomination value : {denomination} </Typography> */}

                                                            {/* <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between' }} >
                                                    <Typography sx={{ fontFamily:"montserrat", fontWeight:500, fontSize:"1rem" }} >Eezib Order Quantity : <span style={{ color:"#b3b3b3" }} ></span>  <span style={{ }} >  </span> </Typography>
                                                        &nbsp;
                                                    <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between', }} >
                                                        <Box component="img" src={eeLogo} sx={{ width:"1rem" }} />  
                                                        &nbsp;
                                                        <Typography sx={{ color:'#d62b2e', fontFamily:'montserrat', fontWeight:500 , color:"#383838", width:`${ width }px`, textAlign:'right'  }} > {value ? parseFloat(totalQuantity).toFixed(2) : parseFloat(quantity).toFixed(2)}     </Typography>
                                                    </Box>
                                                    </Box> */}

                                                            {/* <Typography sx={{ fontFamily:'montserrat' , fontWeight:500 , fontSize:"1.1rem" , }} >* Quantity : {value ? totalQuantity : quantity} </Typography> */}

                                                            {/* {
                                                        e.discount > 0 ? 
                                                        //  <Typography sx={{ fontFamily:'montserrat' , fontWeight:500 , fontSize:"1.1rem" }} >* Eezib discount Rate : {e.discount}%  </Typography>
                                                        <Box sx={{ display:'flex',  alignItems:'center', justifyContent:'space-between' }} >
                                                        <Typography sx={{ fontFamily:"montserrat", fontWeight:500, fontSize:"1rem" }} > Eezib Discount Rate : </Typography>
                                                            &nbsp;
                                                        <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between' }} >
                                                            <Box component="img" src={eeLogo} sx={{ width:"1rem" }} />
                                                            &nbsp;
                                                            <Typography sx={{ color:'#d62b2e', fontFamily:'montserrat', fontWeight:500 , color:"#383838", width:`${ width }px`, textAlign:'right'  }} > {e.discount}% </Typography>
                                                        </Box>
                                                        </Box>
                                                         : null
                                                    } */}
                                                            {
                                                                e.fee > 0 ? (

                                                                    // <Typography sx={{ fontFamily:'montserrat' , fontWeight:500 , fontSize:"1.1rem" }} variant='h4'>* Fee : {e.fee} %</Typography>

                                                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: "space-around" }} >
                                                                        <Typography sx={{ fontFamily: "montserrat", fontWeight: 500, fontSize: "1rem" }} > Eezib Fee : </Typography>
                                                                        &nbsp;
                                                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                                                            <Box component="img" src={eeLogo} sx={{ width: "1.8rem" }} />
                                                                            &nbsp;
                                                                            <Typography sx={{ color: '#d62b2e', fontFamily: 'montserrat', fontWeight: 500, color: "#383838", width: `${width}px`, textAlign: 'right', }} > {e.fee} <span style={{ fontSize: "0.9rem" }} > % </span>  </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                ) : null
                                                            }


                                                            <hr />
                                                            &nbsp;

                                                            {/* <Typography sx={{ fontFamily:'montserrat' , fontWeight:500 , fontSize:"1.1rem" }} >Total Value : { !value ? denomination * quantity : denomination * totalQuantity }</Typography> */}
                                                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: "space-between", width: "96%" }} >
                                                                <Typography sx={{ fontFamily: "montserrat", fontWeight: 500, fontSize: "1rem" }} > Total Value : </Typography>
                                                                &nbsp;
                                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                                                    <Box component="img" src={eeLogo} sx={{ width: "1rem" }} />
                                                                    &nbsp;
                                                                    <Typography sx={{ color: '#d62b2e', fontFamily: 'montserrat', fontWeight: 500, color: "#e05555", width: `${valueWidth}px`, textAlign: 'right' }} > {!value ? parseFloat(openText ? denomiValue * quantity : denomination * quantity)?.toFixed(2) : parseFloat(openText ? denomiValue * totalQuantity : denomination * totalQuantity).toFixed(2)} </Typography>
                                                                </Box>
                                                            </Box>
                                                            {
                                                                e.discount > 0 || e.fee ?
                                                                    // <Typography sx={{ fontFamily:'montserrat' , fontWeight:500 , fontSize:"1.1rem" }} >Eezib Value : ₹ { totalAmount}</Typography> 
                                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: "96%" }} >
                                                                        <Typography sx={{ fontFamily: "montserrat", fontWeight: 500, fontSize: "1rem" }} > Eezib Value : </Typography>
                                                                        &nbsp;
                                                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                                                            <Box component="img" src={eeLogo} sx={{ width: "1rem" }} />
                                                                            &nbsp;
                                                                            <Typography sx={{ color: '#d62b2e', fontFamily: 'montserrat', fontWeight: 500, color: "#6ac253", width: `${valueWidth}px`, textAlign: 'right' }} > {totalAmount} </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                    : null
                                                            }

                                                            <hr />
                                                            &nbsp;
                                                            {
                                                                progress ? <LinearProgress /> :
                                                                    <Button size='small' fullWidth variant='contained' sx={{ fontFamily: 'montserrat', fontWeight: 500, fontSize: '0.8rem' }} onClick={() => checkBalance()} >Place Order</Button>
                                                            }
                                                        </Box>
                                                    </Box>
                                                )
                                            })
                                        }




                                    </DialogContent>

                                </Dialog>


                                <Dialog
                                    open={newOpen}
                                    onClose={handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >


                                    <DialogContent>
                                        {
                                            isLoading ? <CircularProgress /> :
                                                <Box>
                                                    {/* <Typography sx={{ fontFamily: 'montserrat', fontWeight: 500 , marginTop:"0.5rem" }} >{ !errormsg ? item.msg : errormsg }</Typography> */}
                                                    <DisabledByDefaultIcon size="small" onClick={handleClose} sx={{ position: "absolute", marginTop: "-1rem", right: 0, color: 'red' }} />
                                                    <Box sx={{ position: 'relative' }} >
                                                        {
                                                            orderDetails ?
                                                                <Box>
                                                                    &nbsp;

                                                                    <Box sx={{ display: 'flex', alignItems: 'center' }} >
                                                                        <Typography sx={{ fontFamily: 'montserrat', fontWeight: 500 }} >Quantity :&nbsp;</Typography>
                                                                        <Typography sx={{ fontFamily: 'montserrat', fontWeight: 500 }} >{orderDetails.quantity}</Typography>

                                                                    </Box>

                                                                    <Box sx={{ display: 'flex', alignItems: 'center' }} >
                                                                        <Typography sx={{ fontFamily: 'montserrat', fontWeight: 500 }} >Order ID :&nbsp;</Typography>
                                                                        <Typography sx={{ fontFamily: 'montserrat', fontWeight: 500, color: '#3881ff' }} >{orderDetails.order_id}</Typography>
                                                                    </Box>

                                                                    <Box sx={{ display: 'flex', alignItems: 'center' }} >
                                                                        <Typography sx={{ fontFamily: 'montserrat', fontWeight: 500 }} >Total Amount :&nbsp;</Typography>
                                                                        <Typography sx={{ fontFamily: 'montserrat', fontWeight: 500 }} >{orderDetails.total_amount}</Typography>
                                                                    </Box>

                                                                    <Typography sx={{ color: 'gray', fontFamily: 'montserrat', fontWeight: 400, fontSize: "0.9rem", marginTop: "1rem", textAlign: 'center' }} >Thanks for contacting eezib</Typography>
                                                                </Box> : item && error ? <Typography sx={{ fontFamily: 'montserrat', fontWeight: 500, padding: 2 }} >{error}</Typography> : null



                                                        }

                                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', gap: 1, marginTop: '1rem', }} >
                                                            <Button onClick={() => navigate(-1)} size='small' variant='contained' sx={{ fontFamily: 'montserrat', fontWeight: 400, fontSize: "12px" }} >more cards</Button>
                                                            <Button onClick={() => window.location.replace(`${env.REACT_APP_UAPI_URL}/login`)} size='small' variant='contained' sx={{ fontFamily: 'montserrat', fontWeight: 400, fontSize: "12px" }}  >back to eezib</Button>
                                                        </Box>

                                                    </Box>
                                                </Box>
                                        }
                                    </DialogContent>
                                </Dialog>




                                <Dialog
                                    open={loginDialog}
                                    onClose={closeLoginDialog}
                                    aria-describedby="alert-dialog-slide-description"
                                    sx={{ borderRadius: 4 }}
                                >

                                    <DialogContent sx={{ backgroundColor: '#e3e3e3' }} >
                                        {/* <Box component='img' sx={{width:'5rem'}} src={image} /> */}
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>


                                            <Box sx={{ backgroundColor: "white", display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', borderRadius: 2, gap: "0.5rem", backdropFilter: "blur(20px)", boxShadow: "0px 0px 10px 1px white", padding: { lg: "1rem 2rem", xs: "1rem 0.5rem", sm: "2rem 1.5rem" } }} >
                                                {/* <Typography sx={{fontFamily:'montserrat' , fontWeight:700 , fontSize:"1.5rem" ,  marginTop:"1rem"}} >Login</Typography> */}

                                                <Box component='img' sx={{ width: "5rem" }} src={eezib} />


                                                <TextField variant='standard' size='medium' onChange={(e) => setUserName(e.target.value)} value={userName} InputProps={{ endAdornment: (<InputAdornment position='end' > <PersonIcon /> </InputAdornment>) }} className='loginInput' sx={{ width: '16rem', fontFamily: 'montserrat', fontSize: '0.4rem', marginTop: '2rem' }} placeholder=" username" />
                                                <TextField variant='standard' onChange={(e) => setPassword(e.target.value)} value={password} InputProps={{ endAdornment: (<InputAdornment position='end' > <PasswordIcon /> </InputAdornment>) }} className='loginInput' sx={{ width: '16rem', fontFamily: 'montserrat', marginTop: '2rem' }} placeholder=" password" />
                                                <Typography onClick={() => window.location.replace(`${env.REACT_APP_UAPI_URL}/password/reset`)} sx={{ fontFamily: 'montserrat', fontSize: "0.7rem", marginLeft: "auto", cursor: 'pointer' }} >forget password ?</Typography>

                                                <Button onClick={() => getData()} fullWidth size='medium' variant='contained' sx={{ fontFamily: 'montserrat', marginTop: '1.5rem', fontWeight: 500 }} >submit</Button>

                                                <Typography onClick={() => window.location.replace(`${env.REACT_APP_UAPI_URL}/register`)} sx={{ fontFamily: 'montserrat', fontSize: "14px", fontWeight: 500, marginTop: '1rem', cursor: 'pointer', color: "#8a8a8a" }} >not registered yet ?</Typography>
                                            </Box>

                                        </Box>
                                        <DialogActions>
                                            {/* {/* <Button onClick={handleClose}>Disagree</Button> */}
                                            {/* <Button onClick={closeLoginDialog}>Agree</Button>  */}
                                        </DialogActions>
                                    </DialogContent>
                                </Dialog>





                                <Dialog
                                    open={lowbalance}
                                    aria-describedby="alert-dialog-slide-description"
                                    sx={{
                                        borderRadius: 4,
                                        "& .MuiDialog-container": {
                                            "& .MuiPaper-root": {
                                                width: "100%",
                                                maxWidth: { lg: "900px", md: "900px ", sm: "900px", xs: "400px" },  // Set your width here
                                            },
                                        },

                                    }}
                                >
                                    <CloseIcon onClick={() => handleValueCancel()} sx={{ position: "absolute", right: 0, color: '#8c8c8c', cursor: 'pointer' }} />

                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }} >
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} >
                                            {/* <Typography sx={{ fontFamily:"montserrat", fontWeight:500, mt:1 }} > Order Summary </Typography> */}
                                            <Box component="img" src={eezib} sx={{ width: { sm: "5rem", md: "5rem", lg: "7rem", xs: "15rem" }, mt: 1 }} />
                                            <Box component="img" src={pgImage} sx={{ width: { sm: "20rem", md: "25rem", lg: "25rem", xs: "15rem" } }} />
                                        </Box>
                                        <Box sx={{}} >
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "center", flexDirection: 'row' }}  >

                                                <Box sx={{ display: 'flex', alignItems: 'left', flexDirection: 'column' }} >
                                                    <Typography sx={{ fontFamily: "montserrat", fontWeight: 500, fontSize: { lg: "1rem", md: "1rem", sm: "0.9rem", xs: "0.8rem" } }} > Eezib Order Value &nbsp; <span style={{ color: "#b3b3b3" }} ></span>  <span style={{}} >  </span> </Typography>


                                                    <Typography sx={{ fontFamily: "montserrat", fontWeight: 500, fontSize: { lg: "1rem", md: "1rem", sm: "0.9rem", xs: "0.8rem" } }} style={{ display: lowBalanceData?.is_wallet === 0 ? 'none' : '' }} > Eezib Coins  <span style={{ color: "#b3b3b3" }}></span>  <span style={{}} >  </span> </Typography>

                                                    <Typography sx={{ fontFamily: "montserrat", fontWeight: 500, fontSize: { lg: "1rem", md: "1rem", sm: "0.9rem", xs: "0.8rem" } }} style={{ display: lowBalanceData?.is_wallet === 0 ? 'none' : '' }} > Eezib Topup Value  <span style={{ color: "#b3b3b3" }} ></span>  <span style={{}} >  </span> </Typography>

                                                    {
                                                        lowBalanceData?.block_amount ? <Typography sx={{ fontFamily: "montserrat", fontWeight: 500, fontSize: { lg: "1rem", md: "1rem", sm: "0.9rem", xs: "0.8rem" }, color: "red" }} > Eezib Topup Value  <span style={{ color: "#b3b3b3" }} ></span>  <span style={{}} >  </span> </Typography>
                                                            : null
                                                    }
                                                </Box>

                                                <Box sx={{ display: 'flex', alignItems: 'left', flexDirection: 'column' }} >
                                                    <Typography sx={{ fontFamily: "montserrat", fontWeight: 500, fontSize: { lg: "1rem", md: "1rem", sm: "0.9rem", xs: "0.8rem" } }} >:</Typography>

                                                    <Typography sx={{ fontFamily: "montserrat", fontWeight: 500, fontSize: { lg: "1rem", md: "1rem", sm: "0.9rem", xs: "0.8rem" } }} style={{ display: lowBalanceData?.is_wallet === 0 ? 'none' : '' }}>:</Typography>

                                                    <Typography sx={{ fontFamily: "montserrat", fontWeight: 500, fontSize: { lg: "1rem", md: "1rem", sm: "0.9rem", xs: "0.8rem" } }} style={{ display: lowBalanceData?.is_wallet === 0 ? 'none' : '' }} >:</Typography>

                                                    {
                                                        lowBalanceData?.block_amount ?
                                                            <Typography sx={{ fontFamily: "montserrat", fontWeight: 500, fontSize: { lg: "1rem", md: "1rem", sm: "0.9rem", xs: "0.8rem" } }} >:</Typography>
                                                            : null
                                                    }

                                                </Box>



                                                <Box sx={{ display: 'flex', alignItems: 'left', flexDirection: 'column', p: 1 }} >
                                                    <Box component="img" src={eeLogo} sx={{ width: { lg: "1.6rem", md: "1.6rem", sm: "1.6rem", xs: "1rem" }, mt: 0.5, mb: 0.5 }} />

                                                    <Box component="img" src={eeLogo} sx={{ width: { lg: "1.6rem", md: "1.6rem", sm: "1.6rem", xs: "1rem" }, mt: 0.5, mb: 0.5 }} style={{ display: lowBalanceData?.is_wallet === 0 ? 'none' : '' }} />

                                                    <Box component="img" src={eeLogo} sx={{ width: { lg: "1.6rem", md: "1.6rem", sm: "1.6rem", xs: "1rem" }, mt: 0.5, mb: 0.5 }} style={{ display: lowBalanceData?.is_wallet === 0 ? 'none' : '' }} />
                                                    {
                                                        lowBalanceData?.block_amount ?
                                                            <Box component="img" src={eeLogo} sx={{ width: { lg: "1.6rem", md: "1.6rem", sm: "1.6rem", xs: "1rem" }, mt: 0.5, mb: 0.5 }} /> : null
                                                    }

                                                </Box>

                                                <Box sx={{ display: 'flex', alignItems: 'left', flexDirection: 'column', p: 1 }} >
                                                    <Typography sx={{ color: '#d62b2e', fontFamily: 'montserrat', textAlign: "right", fontWeight: 500, fontSize: { lg: "1rem", md: "1rem", sm: "0.9rem", xs: "0.8rem" } }} >{lowBalanceData?.order_amount}</Typography>

                                                    <Typography sx={{ color: '#d62b2e', fontFamily: 'montserrat', textAlign: "right", fontWeight: 500, fontSize: { lg: "1rem", md: "1rem", sm: "0.9rem", xs: "0.8rem" } }} style={{ display: lowBalanceData?.is_wallet === 0 ? 'none' : '' }} > {lowBalanceData?.wallet_amount} </Typography>

                                                    {lowBalanceData?.topup_amount === null || 0 ? null :
                                                        <Typography sx={{ color: '#d62b2e', fontFamily: 'montserrat', textAlign: "right", fontWeight: 500, fontSize: { lg: "1rem", md: "1rem", sm: "0.9rem", xs: "0.8rem" } }} style={{ display: lowBalanceData?.is_wallet === 0 ? 'none' : '' }}> {lowBalanceData?.topup_amount} </Typography>
                                                    }
                                                    {
                                                        lowBalanceData?.block_amount ? <Typography sx={{ color: '#d62b2e', fontFamily: 'montserrat', textAlign: "right", fontWeight: 500, fontSize: { lg: "1rem", md: "1rem", sm: "0.9rem", xs: "0.8rem" } }} > {lowBalanceData?.block_amount} </Typography>
                                                            : null
                                                    }
                                                </Box>

                                            </Box>
                                            <Box onClick={() => handleRedeem()} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: "0.2rem", cursor: "pointer" }} style={{ display: lowBalanceData?.is_wallet === 0 ? 'none' : '' }}>
                                                <Typography sx={{ fontFamily: 'poppins', fontWeight: 500, textAlign: 'center', color: '#1483FA', fontSize: "0.8rem" }} >Redeem points</Typography>
                                                <Box component="img" src={coinImage} sx={{ width: "1rem" }} />
                                            </Box>
                                            <Divider fullWidth sx={{ marginTop: "0rem", marginBottom: "1rem" }} />


                                            <Box>



                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: 2, width: "15rem" }} >
                                                    {/* <Typography sx={{ fontFamily:'montserrat', fontWeight:400, color:"#2a9adb", textTransform:'capitalize', fontSize:"0.8rem", mt:1 }} >Add Money to pay </Typography> */}
                                                    <Box sx={{ mt: 2, border: '1px solid #ababab', width: { lg: "20rem", md: "20rem", sm: "17rem", xs: "15rem" }, borderRadius: 2 }}
                                                        style={{ display: lowBalanceData?.is_wallet === 0 ? 'none' : '' }}
                                                    >

                                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 2, padding: "0px 2px" }} >

                                                            <Checkbox
                                                                // checked={selectedValue === 'a'}
                                                                onChange={handleCheck}
                                                                value="a"
                                                                name="radio-buttons"
                                                            // inputProps={{ 'aria-label': 'A' }}
                                                            // disabled={ lowBalanceData?.topup_amount !== 0 }

                                                            />

                                                            <Typography sx={{ fontFamily: 'montserrat', fontWeight: 500, textTransform: "capitalize", color: lowBalanceData?.topup_amount !== 0 ? "gray" : "black", fontSize: { lg: "1rem", md: "1rem", sm: "0.9rem", xs: "0.7rem" } }} >Proceed With Coins</Typography>

                                                            <Box component='img' src={eeLogo} sx={{ width: { lg: "2rem", md: "2rem", sm: "2rem", xs: "1.5rem" }, padding: "0px 5px" }} />

                                                        </Box>

                                                        {/* {
                                                                progress2 ? <CircularProgress/> : 
                                                        <Button onClick={() => handleWalletRequest() } fullWidth endIcon={ <AccountBalanceWalletIcon/> } disabled={ lowBalanceData?.topup_amount !== null } variant='contained' sx={{ fontFamily:'montserrat', fontWeight:500, fontSize:"0.9rem" }} >Purchase from wallet</Button>
                                                            } */}
                                                        {
                                                            lowBalanceData?.topup_amount === 0 ? null :
                                                                <Typography sx={{ fontFamily: 'montserrat', fontWeight: 500, fontSize: { lg: "0.7rem", md: "0.6rem", sm: "0.7rem", xs: "0.6rem" }, color: "#fc5b5b", textAlign: 'center' }} > Topup Required - ee {lowBalanceData?.topup_amount} </Typography>
                                                        }
                                                    </Box>

                                                    <Box sx={{ display: 'flex', alignItems: "center", justifyContent: 'center', gap: 1 }}
                                                        style={{ display: lowBalanceData?.qr_status === 0 ? 'none' : '' }}
                                                    >

                                                        <Box sx={{ border: "1px solid #ababab", width: { lg: "20rem", md: "20rem", sm: "17rem", xs: "15rem" }, borderRadius: 2 }} >


                                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 2, padding: "0px 2px" }} >

                                                                <Radio
                                                                    checked={selectedValue === 'c'}
                                                                    onChange={handleChange}

                                                                    checkedIcon={<RadioButtonCheckedIcon />}
                                                                    value="c"
                                                                    name="radio-buttons"
                                                                    inputProps={{ 'aria-label': 'C' }}
                                                                    disabled={process.env.REACT_APP_UAPI_URL === "https://uat.eezib.in" || process.env.REACT_APP_UAPI_URL === "https://client.eezib.in"}
                                                                />

                                                                <Typography sx={{ fontFamily: 'montserrat', fontWeight: 500, fontSize: { lg: "1rem", md: "1rem", sm: "0.9rem", xs: "0.8rem" }, textTransform: "capitalize", color: lowBalanceData?.qr_status === 0 || process.env.REACT_APP_UAPI_URL === "https://uat.eezib.in" || process.env.REACT_APP_UAPI_URL === "https://client.eezib.in" ? "gray" : "black" }} >Scan With QR</Typography>

                                                                <Box component='img' src={qrIcon} sx={{ width: { lg: "2rem", md: "2rem", sm: "2rem", xs: "1.5rem" }, padding: "0px 5px" }} />

                                                            </Box>

                                                        </Box>
                                                        {
                                                            selectedValue === "c" ?
                                                                <CloseIcon value="" onClick={handleChange} sx={{ color: "white", backgroundColor: 'red', fontSize: "1rem", borderRadius: '10px 10px 10px 0px', cursor: 'pointer' }} />
                                                                : null
                                                        }
                                                    </Box>

                                                    <Box sx={{ display: 'flex', alignItems: "center", justifyContent: 'center', gap: 1 }}
                                                        style={{ display: lowBalanceData?.pg_status === 0 ? 'none' : '' }}>

                                                        <Box sx={{ border: "1px solid #ababab", width: { lg: "20rem", md: "20rem", sm: "17rem", xs: "15rem" }, borderRadius: 2 }} >


                                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 2, padding: "0px 2px" }} >

                                                                <Radio
                                                                    checked={selectedValue === 'b'}
                                                                    onChange={handleChange}
                                                                    value="b"
                                                                    name="radio-buttons"
                                                                    inputProps={{ 'aria-label': 'B' }}
                                                                    disabled={lowBalanceData?.pg_status === 0}
                                                                />

                                                                <Typography sx={{ fontFamily: 'montserrat', fontWeight: 500, fontSize: { lg: "1rem", md: "1rem", sm: "0.9rem", xs: "0.8rem" }, textTransform: "capitalize", color: lowBalanceData?.pg_status === 0 ? "gray" : "black" }} >other Options</Typography>

                                                                <Box component='img' src={cardIcon} sx={{ width: { lg: "2rem", md: "2rem", sm: "2rem", xs: "1.5rem" }, padding: "0px 5px" }} />

                                                            </Box>

                                                            {/* {
                                                            progress ?   <CircularProgress/> :
                                                            <Button fullWidth00000p disabled={ lowBalanceData?.pg_status === 0 || progress2 } onClick={() => jaiGateway()} endIcon={ <AccountBalanceIcon/> } variant='contained' sx={{ fontFamily:'montserrat', fontWeight:500, mt:1 }} > purchase more coins </Button>
                                                        } */}
                                                        </Box>
                                                        {
                                                            selectedValue === "b" ?
                                                                <CloseIcon value="" onClick={handleChange} sx={{ color: "white", backgroundColor: 'red', fontSize: "1rem", borderRadius: '10px 10px 10px 0px', cursor: 'pointer' }} />
                                                                : null
                                                        }
                                                    </Box>

                                                    {
                                                        progress || progress2 ? <CircularProgress /> :
                                                            <Button disabled={progress2} variant='contained' onClick={() => selectedValue === '' && proceedCoin === 1 ? handleWalletRequest() : selectedValue === 'b' ? jaiGateway() : selectedValue === 'c' && proceedCoin === 0 ? handleQr(0) : selectedValue === 'c' && proceedCoin === 1 ? handleQr(1) : handleExceptErr()} sx={{ fontFamily: 'montserrat', fontWeight: 400, width: { lg: "20rem", md: "20rem", sm: "17rem", xs: "15rem" }, mb: { xs: "1rem" } }} > make payment </Button>
                                                        // <Button disabled={ progress2 } variant='contained' onClick={ () =>  console.log("selected value : ",selectedValue, ".......   Proceed coin : ",proceedCoin)  } sx={{ fontFamily:'montserrat', fontWeight:400, width:{lg:"20rem", md:"20rem", sm:"17rem", xs:"15rem" } , mb:{xs:"1rem"} }} > make payment </Button>

                                                    }

                                                </Box>
                                            </Box>
                                        </Box>


                                    </Box>

                                </Dialog>





                                <Dialog
                                    open={openUrlDialogue}
                                    aria-describedby="alert-dialog-slide-description"
                                    sx={{
                                        borderRadius: 4, "& .MuiDialog-container": {
                                            "& .MuiPaper-root": {
                                                width: "100%",
                                                maxWidth: "500px",  // Set your width here
                                            },
                                        },
                                    }}
                                >

                                    <DialogContent sx={{ backgroundColor: '#e3e3e3' }} >

                                        <Box
                                            sx={{ width: "100%", margin: 0, padding: 0 }}
                                        >
                                            <iframe
                                                width="100%"
                                                height="500px"
                                                src={pgUrl}
                                                title="thanks for choosing eezib"
                                            />
                                        </Box>

                                    </DialogContent>
                                </Dialog>



                            </Box>
                        )
                    })
            }


            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={handleSuccess}
                onClose={successClose}
                autoHideDuration={2000}>
                <Alert severity="success" sx={{ width: '100%' }}>{jsonSuccess}</Alert>
            </Snackbar>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={handleError}
                onClose={errorClose}
                autoHideDuration={2000} >
                <Alert severity="error" sx={{ width: '100%' }}>{jsonError}</Alert>
            </Snackbar>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={warningOpen}
                onClose={warningclose}
                autoHideDuration={2000} >
                <Alert severity="warning" sx={{ width: '100%' }}>{warningText}</Alert>
            </Snackbar>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleClose}>Eezib Gift Voucher</MenuItem>

            </Menu>

            <Dialog
                open={openQr}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ borderRadius: 3 }}
            >

                <CloseIcon onClick={handleOpenQr} sx={{ cursor: 'pointer', position: 'absolute', right: 0, color: 'red', fontSize: "1.2rem", }} />
                <DialogContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}  >
                        <Box component="img" src={eezib} sx={{ width: "7rem" }} />
                        <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: "0.8rem" }} >QR Accepted Here</Typography>
                        <Divider variant='middle' sx={{ width: "100%", mt: 1 }} />
                        <Typography sx={{ fontFamily: 'Poppins', fontWeight: 450, color: "#34449B", fontSize: "0.8rem", mt: 2 }} >  {sessionStorage.getItem("messageUPI")} </Typography>

                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', mt: 0 }}  >
                            {
                                parseInt(lowBalanceData?.topup_amount) > 0 ?
                                    <Typography sx={{ fontFamily: 'Poppins', fontSize: '0.85rem', color: '#eb5757' }} > Topup Required - ₹ {lowBalanceData?.topup_amount} </Typography> :
                                    null
                            }
                            <div style={{ width: "14rem" }} dangerouslySetInnerHTML={{ __html: qrCode }} />

                            <LinearProgress sx={{ width: "100%", mt: 1 }} />

                            <Typography sx={{ fontFamily: 'Poppins', fontWeight: 450, color: "#34449B", fontSize: "0.8rem", mt: 1 }} >  {sessionStorage.getItem("userEmail")} </Typography>
                            <Typography sx={{ fontFamily: 'Poppins', fontWeight: 450, color: "#34449B", fontSize: "0.8rem" }} >  {sessionStorage.getItem("userPhone")} </Typography>
                            {/* <Typography sx={{ fontFamily:'Poppins', fontWeight:500, fontSize:"0.8rem", mt:1 }} >Scan & Pay</Typography> */}
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', width: "100%", mt: "0.8rem", mb: 2 }} >
                            <Box component='img' src={upiImg} sx={{ width: "5rem" }} />
                            <Box component="img" src={bupi} sx={{ width: "6rem" }} />
                        </Box>
                        <Divider variant='middle' sx={{ width: "100%" }} />
                        <Box sx={{ fontFamily: "Poppins", fontWeight: 400, color: 'gray', textTransform: "capitalize", fontSize: "0.7rem", mt: 1 }} >
                            Session will be expire in {seconds} seconds
                        </Box>

                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog
                // open={true}
                open={successDialogue}
                onClose={handleSDialogue}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ borderRadius: 3 }}>


                <CloseIcon onClick={handleOpenQr} sx={{ cursor: 'pointer', position: 'absolute', right: 0, color: 'red', fontSize: "1.2rem", }} />
                <DialogContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', margin: 0, paddding: 0 }} >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}  >
                        <Box component='img' src={eezib} sx={{ width: '6rem' }} />
                        <Divider variant='middle' sx={{ width: "100%", mt: 1.5 }} />
                        <Box component='img' src={orderImage} sx={{ width: { lg: "28rem", xs: "18rem" }, padding: 0, margin: 0, mt: 2 }} />
                        <Typography className='orderText' sx={{ fontFamily: 'Poppins', fontWeight: 700, fontSize: "2.5rem", textTransform: 'capitalize', mt: 3 }} >Thank You.</Typography>
                        <Typography className='errorText' sx={{ fontFamily: 'Poppins', fontWeight: 500, color: "", fontSize: { lg: "1.1rem", sm: "1rem", md: "1.1rem", xs: "0.7rem" }, textTransform: 'capitalize' }} > Order placed successfully, Happy Shopping.</Typography>
                        {/* <Typography  sx={{ fontFamily:'Poppins', fontWeight:400 ,fontSize:"0.75rem", color:"gray", textTransform:'capitalize'  }} >Thanks for shopping and trusting eezib  </Typography>                 */}

                    </Box>
                </DialogContent>

            </Dialog>



            <Dialog
                open={openRedeem}
                onClose={handleRedeemClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <CloseIcon onClick={handleRedeemClose} sx={{ position: 'absolute', right: 0, fontSize: "1.2rem", color: "red", cursor: "pointer" }} />
                <DialogContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} >
                    <Box >
                        <Box component='img' src={eezib} sx={{ width: "7rem" }} />
                    </Box>
                    <Divider sx={{ width: "100%", mt: 2 }} />
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: { lg: "row", sm: "row", md: "row", xs: "column" } }} >
                        <Box component="img" src={redeem} sx={{ width: "15rem" }} />
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '0.5rem', width: "100%" }} >
                            <Typography sx={{ fontFamily: 'poppins', fontWeight: 500, fontSize: "1.1rem", color: '#757575' }} >Current <Box component="img" src={eeLogo} sx={{ width: "1.3rem" }} /> Points..</Typography>
                            <Typography sx={{ fontFamily: 'poppins', fontWeight: 400, fontSize: "1.2rem", textAlign: 'center', backgroundColor: "#5385e0", padding: "0.5rem", color: "white", borderRadius: "2rem", width: "12rem" }} >{redeemCoins}</Typography>


                            <Box sx={{ width: "12rem", mt: '1rem' }} >
                                <label style={{ fontFamily: 'poppins', fontSize: "0.8rem", color: 'gray' }} >Enter Redemption Points</label>
                                <Input type='number' value={redemAmount} onChange={handleRedemAmount} startAdornment={
                                    <InputAdornment position='start'>
                                        <Box src={coins} alt="coin" component="img" sx={{ width: "1.5em", borderRadius: 50 }} />
                                        {/* <CancelOutlined/> */}
                                    </InputAdornment>
                                } size="small" />
                                {
                                    redemAmount < 10 ?
                                        <label style={{ fontSize: "0.6rem", fontWeight: 500, color: "gray" }} >Min Redemption - 10 points</label> : null
                                }
                            </Box>
                            {
                                redemProg ? <CircularProgress /> :
                                    <Button variant='contained' disabled={redemAmount < 10} onClick={() => redemCoins()} fullWidth sx={{ fontFamily: 'Poppins', borderRadius: '12rem', mt: 2 }} >Submit</Button>
                            }
                        </Box>
                    </Box>
                    <Divider sx={{ width: "100%", borderStyle: "dashed" }} />
                    <label style={{ textalign: 'center', fontFamily: 'poppins', fontWeight: 400, fontSize: "0.8rem", marginTop: "0.5rem", marginBottom: "-0.5rem", color: "darkslategray" }} > Note - {coinValue} points redeems 1 eezib coin 😊 </label>
                </DialogContent>
            </Dialog>



            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={progress3}
                onClick={handlebackClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Footer />



        </Box>
    )
}

export default Voucher