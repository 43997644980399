import { Box, Typography } from '@mui/material'
import React from 'react'

const Footer = () => {
  return (
      <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between', backgroundColor:"#0095ff", p:0.5 , width:"100%", position:'fixed', bottom:0, zIndex:1000  }} >
      <Typography sx={{ fontFamily:"montserrat", fontWeight:500, color:'white', marginLeft:2, fontSize:{xs:"0.6rem", sm:"0.8rem", md:"0.8rem", lg:"0.8rem"} }} >© 2021 Copyright. All Rights Reserved.</Typography>
      <Typography sx={{ fontFamily:"montserrat", fontWeight:400, color:'white', marginRight:5, fontSize:{xs:"0.6rem", sm:"0.9rem", md:"0.9rem", lg:"0.9rem"} }} >Design By <span onClick={() => window.scroll(0,0)} style={{ color:"white", cursor:"pointer", fontWeight:'600' }} > eezib</span> </Typography>
      </Box>
  )
}

export default Footer