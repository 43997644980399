import { combineReducers, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authReducer from "./slice";
import welcomeReducer from './welcome';
import orderReducer from "./order";
import loginReducer from "./userAuth";
import thunk from "redux-thunk";

const middleware = [...getDefaultMiddleware(), thunk]

 const rootReducer = combineReducers({
        auth:authReducer,
        welcome:welcomeReducer,
        order:orderReducer,
        login:loginReducer
})

export const store = configureStore({
    reducer:rootReducer,
    middleware
})