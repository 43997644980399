import { Box, CircularProgress, Snackbar, Typography } from '@mui/material'
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import env from "react-dotenv";
import { useDispatch, useSelector } from 'react-redux';
import { welcomeSlice } from '../redux/welcome';
import { login } from '../redux/userAuth';
import { ApiFile } from './ApiHandler';
import Cookies from 'js-cookie';

function FrontPage() {

  const [verification, setVerification] = React.useState("");
  const [userId, setUserid] = React.useState("");
  const [name , setName] = React.useState('')


  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const webLocation = window.location.search
  const token = webLocation.replace("?home=", "");


  console.log("token data : ",token);

  async function getData() {

    try{


      const api = process.env.REACT_APP_UAPI_URL;
      // window.alert(api)
      // const apiUrl = `${api}/eezibapi/auth`;
      const apiUrl = `${api}/api/validate_user`;
      const method = "POST";

      console.log("insider auth token : ",token);
      const json = await ApiFile({"apiUrl":apiUrl , "method":method, "authAccess":token });

      if(json){
        if(json.status === "success"){

          const token = json.data.access_token;
          const name = json.data.user.name;
          const id = json.data.user.id;
          const qr = json.data?.QR;
          // Cookies.set("voucherDemoQr", json?.data?.QR);
          sessionStorage.setItem("voucherDemoQr",json.data?.QR);
          Cookies.set("voucherDemoAuth",json.data.access_token);
          Cookies.remove("demoCAuth");
          Cookies.set("demoCAuth",token);
          Cookies.set("voucherDemoName",json.data.user.name);
          Cookies.set("voucherDemoUserId",json.data.user.id); 
          Cookies.set("voucherDemoUserNumber",json.data.user.phone_no); 

          sessionStorage.setItem("messageQr",json.data.kyc_msg);
          sessionStorage.setItem("messageUPI",json.data.upi);
          sessionStorage.setItem("userEmail",json.data.user.email);
          sessionStorage.setItem("userPhone",json.data.user.phone_no);
          sessionStorage.setItem("userPhone",json.data.user.phone_no);

        console.log( " check validation : ", token , name , id);
        console.log("true");

        if (token && name && id) {
          console.log('All values are true');
          navigate("/products", { state: { data: [{ "jwt": token, "userDetails": id, "name": name, "qr":qr }] } })
        } else {
          console.log('Some values are false or undefined');
        }

        }

        if(json.status === "error"){
          window.alert(json.message);
          // setTimeout(() => {
          //   window.location.replace(`${env.REACT_APP_UAPI_URL}`)
          // },800)        
          }

      }


    }catch(err){
      
      console.log("validation catch error : ", err);

      setTimeout(() => {
        window.location.replace(`${env.REACT_APP_UATAPI_URL}`)
      },800)
    }




    }

    React.useEffect(() => {
      if(token){
        getData()
      }
      else if(location?.state?.error){
        console.log("location state data : ", location.state.error );

        const id = location.state.error

       // navigate("/products", {state : { error : id }})

      }
      else{
      //  window.location.replace(`${env.REACT_APP_UAPI_URL}`)
      //  window.alert("unAuthorized access");
      navigate("/products")
      } 
  }, [token]);



  return (
    <Box sx={{display:'flex' , alignItems:'center' , justifyContent:'center'}} >
      <Box sx={{display:'flex' , alignItems:'center' , justifyContent:'center', marginTop:'10%'}} >
          <span style={{ display:'flex' , alignItems:'center' , justifyContent:'center' , flexDirection:'column' }} >
            <CircularProgress size="12rem" thickness={1.5} />
              <Typography sx={{fontFamily:'montserrat' , fontSize:'1rem' , marginTop:"0.5rem"}} >Redirecting to Eezib Cards Mania...</Typography>
          </span>
          
      </Box>

     
    </Box>
  )
}

export default FrontPage